import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import {
  exactDuration,
  humanDateTime,
  timeAgo,
  humanDuration,
} from 'src/utility/time'
import { useSite } from 'src/contexts/site'
import { Text, Card, Tooltip, Icon, Button } from 'src/components/ui'
import { useSetAnomalyStateMutation } from 'anomalies/api'
import { Anomaly, GqlAnomalyState } from 'src/services'
import { AnomalyLiveTag } from 'anomalies/anomalies'
import { mapReadableAnomalyState } from 'anomalies/anomaly.utils'

interface AnomalyOverviewProps {
  anomaly: Anomaly
  className?: string
}

export function Overview({
  anomaly,
  className,
}: AnomalyOverviewProps): JSX.Element {
  const { rootLink } = useSite()
  const mutation = useSetAnomalyStateMutation()

  function handleAnomalyStateChange(state: GqlAnomalyState): void {
    mutation.mutateAsync(
      {
        anomalyId: anomaly.id,
        state,
      },
      {
        onError: () => {
          toast.error('Failed to update anomaly status.', {
            position: 'top-right',
          })
        },
        onSuccess: () => {
          toast.success('Anomaly status updated.', {
            position: 'top-right',
          })
        },
      },
    )
  }

  return (
    <Card className={classNames('@container', className)}>
      <div className="flex w-full flex-wrap items-center justify-between gap-xs">
        <Text variant="title" bold className="!text-2xl">
          Anomaly {anomaly.id}
        </Text>
        <AnomalyLiveTag
          inProgress={anomaly.inProgress}
          className="w-full max-w-[100px]"
        />
      </div>
      <div>
        <Text className="my-xs">
          Model:{' '}
          <Link to={`${rootLink}/models/${anomaly.model.id}`}>
            {anomaly.model.name}
          </Link>
        </Text>
      </div>
      <Text className="my-xs">
        Start time: {humanDateTime(anomaly.start)} ({timeAgo(anomaly.start)})
      </Text>
      {anomaly.end && (
        <Text className="my-xs">
          Duration:{' '}
          <span title={exactDuration(anomaly.start, anomaly.end)}>
            {humanDuration(anomaly.start, anomaly.end)}
          </span>
        </Text>
      )}
      <Text className="my-xs">
        Peak score:{' '}
        <span>{anomaly.score ? anomaly.score.toFixed(4) : '-'}</span>
      </Text>
      <hr className="my-s bg-border" />
      <div className="flex flex-col gap-2xs">
        <div className="flex items-center gap-2xs">
          <Text bold>Anomaly Status</Text>
          <Text>
            {(
              [
                GqlAnomalyState.InProgress,
                GqlAnomalyState.New,
              ] as GqlAnomalyState[]
            ).includes(anomaly.state)
              ? 'Need Review'
              : mapReadableAnomalyState(anomaly.state)}
          </Text>
        </div>
        <div className="flex items-center gap-xs">
          <Text>Update anomaly status</Text>
          <Tooltip direction="right" render={() => <Text>TBD</Text>}>
            <Icon icon={light('question-circle')} />
          </Tooltip>
        </div>
        <div className="mt-xs flex flex-col items-start gap-s @md:flex-row @md:items-center">
          <Button
            variant="secondary"
            title="Is Anomaly"
            disabled={
              mutation.isLoading || anomaly.state === GqlAnomalyState.Anomaly
            }
            onClick={() => handleAnomalyStateChange(GqlAnomalyState.Anomaly)}
          />
          <Button
            variant="secondary"
            title="Not Anomaly"
            disabled={
              mutation.isLoading || anomaly.state === GqlAnomalyState.NotAnomaly
            }
            onClick={() => handleAnomalyStateChange(GqlAnomalyState.NotAnomaly)}
          />
          <Button
            variant="icon-secondary"
            title="Uncertain"
            disabled={
              mutation.isLoading || anomaly.state === GqlAnomalyState.Uncertain
            }
            onClick={() => handleAnomalyStateChange(GqlAnomalyState.Uncertain)}
          />
        </div>
      </div>
    </Card>
  )
}

import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { SelectInput, Text } from 'src/components/ui'

type Props = {
  pageSize: {
    value: string
    label: string
  }
  pageSizes?: number[]
  onChange: (pageSize: string) => void
}

const defaultPageSizes = [5, 10, 25, 100]

function PageSizeSelector({
  pageSize,
  pageSizes,
  onChange,
}: Props): JSX.Element {
  const pageSizesToUse = pageSizes ?? defaultPageSizes
  const options = pageSizesToUse?.map(pageSize => ({
    value: pageSize.toString(),
    label: pageSize,
  }))
  return (
    <div className="flex items-center gap-xs">
      <Text variant="description">Items per Page</Text>
      <SelectInput
        options={options}
        onChange={onChange}
        value={pageSize}
        direction="up"
      />
    </div>
  )
}

type PortalProps = {
  target: Element
  pageSizes?: number[]
  onChange: (pageSize: string) => void
  pageSize: {
    value: string
    label: string
  }
}

export default function PageSizeSelectorPortal({
  target,
  pageSizes,
  onChange,
  pageSize,
}: PortalProps): JSX.Element | null {
  const [container, setContainer] = useState<any>(null)

  useEffect(() => {
    if (target && !container) {
      const element = document.createElement('div')
      setContainer(element)
      target.insertBefore(element, target.firstChild)
    }

    return () => {
      if (container && container.parentNode === target) {
        target.removeChild(container)
        setContainer(null)
      }
    }
  }, [container, target])

  if (!container) return null

  return createPortal(
    <PageSizeSelector
      pageSizes={pageSizes}
      onChange={onChange}
      pageSize={pageSize}
    />,
    container,
  )
}

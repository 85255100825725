import { memo, useMemo } from 'react'
import { subDays } from 'date-fns'
import { Banner, Button, Spinner } from 'src/components/ui'
import { useFavouriteModelsIds } from 'models/api'
import { useAnomalies } from 'anomalies/api'
import { useSite } from 'src/contexts/site'
import { AnomalyDistribution, NoFavoriteModels } from './components'

export const AnomalyDistributionCard = memo(
  function AnomalyDistributionCard(): JSX.Element {
    const { id } = useSite()
    const favoriteModelsQuery = useFavouriteModelsIds()
    const now = useMemo(() => new Date(), [])
    const from = subDays(now, 7)
    const anomaliesQuery = useAnomalies(
      id,
      from.valueOf(),
      now.valueOf(),
      data => data.filter(a => favoriteModelsQuery.data?.has(a.model.id)),
    )

    if (favoriteModelsQuery.isLoading || anomaliesQuery.isLoading) {
      return <Spinner />
    }

    if (favoriteModelsQuery.isError || anomaliesQuery.isLoading) {
      const errorQuery = favoriteModelsQuery.isError
        ? favoriteModelsQuery
        : anomaliesQuery
      return (
        <Banner
          variant="error"
          rightComponent={
            <Button
              variant="primary"
              title="Try again"
              onClick={() => errorQuery.refetch()}
            />
          }
        >
          Failed to fetch data
        </Banner>
      )
    }

    if (favoriteModelsQuery.data.size === 0) {
      return <NoFavoriteModels />
    }

    return <AnomalyDistribution anomalies={anomaliesQuery.data ?? []} />
  },
)

import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { OnlyModelProps, SiteRole } from 'src/types'
import { useSite } from 'src/contexts/site'
import { Text, TextInput, Icon, Tooltip } from 'src/components/ui'
import { useAddStarMutation, useRemoveStarMutation } from 'models/api'
import { ModelState } from '../../ModelState'
import { isModelEditing, modelValues } from '../modelOverview.state'

export function Name({ model }: OnlyModelProps): JSX.Element {
  const { viewerRole: role, id: factory } = useSite()
  const removeStar = useRemoveStarMutation(factory)
  const addStar = useAddStarMutation(factory)

  return (
    <div className="flex flex-col gap-xs small:flex-row small:items-center">
      <div className="flex items-center gap-xs">
        <Tooltip
          direction="bottom-start"
          className="!p-xs"
          render={() => (
            <Text variant="description">
              {model.viewerHasStarred
                ? 'Remove from favorites'
                : 'Add to favorites'}
            </Text>
          )}
        >
          <Icon
            className={classNames(
              'cursor-pointer',
              model.viewerHasStarred && 'text-[orange]',
            )}
            size="regular"
            onClick={async () => {
              if (addStar.isLoading || removeStar.isLoading) return
              if (model.viewerHasStarred) {
                await removeStar.mutateAsync({
                  starrableId: model.id,
                })
              } else {
                await addStar.mutateAsync({
                  starrableId: model.id,
                })
              }
            }}
            icon={
              model.viewerHasStarred ? solid('star-sharp') : light('star-sharp')
            }
          />
        </Tooltip>

        {isModelEditing.value && role !== SiteRole.READER ? (
          <TextInput
            value={modelValues.name.value}
            onChange={e => (modelValues.name.value = e.target.value)}
            containerStyles="!py-0"
            inputStyles="!text-2xl large:min-w-[420px]"
          />
        ) : (
          <Text variant="title" bold className="!text-2xl">
            {model.name}
          </Text>
        )}
      </div>
      <div>
        <ModelState state={model.state} />
      </div>
    </div>
  )
}

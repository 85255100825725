import {
  NOT_RUNNING,
  RUNNING,
  label,
  STREAMING,
} from 'models/model/model.utils'
import { DynamicTable, Text, Spinner, Banner, Button } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { ChartAxis, ChartTableProps } from 'src/types/chartTypes'
import { useForecastModels } from 'models/api'
import { getForecastConfig } from './table-configs'

export function ForecastsTable(props: ChartTableProps): JSX.Element {
  return (
    <div className="h-full">
      <Text variant="title" bold>
        Select Forecasts to View
      </Text>
      <Table {...props} />
    </div>
  )
}

function Table({
  chart,
  addSeries,
  removeSeries,
}: ChartTableProps): JSX.Element {
  const forecastModelsQuery = useForecastModels()
  const { id: factory } = useSite()

  if (forecastModelsQuery.isError) {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Try again"
            onClick={() => forecastModelsQuery.refetch()}
          />
        }
      >
        Failed to load forecast models
      </Banner>
    )
  }

  if (forecastModelsQuery.isLoading) {
    return <Spinner />
  }

  const models = forecastModelsQuery.data
    .filter(({ state }) => [RUNNING, NOT_RUNNING, STREAMING].includes(state))
    .map(item => {
      return {
        ...item,
        tag: {
          ...item.tag,
          displayTagName: item.tag.displayName
            ? item.tag.displayName
            : item.tag.tagName || '',
        },
        label: label(item.state),
      }
    })

  const selected = chart.data.flatMap(({ id, type }) =>
    type === 'forecast' ? [id] : [],
  )

  const selectedScatter = chart.data.flatMap(({ id, axis }) =>
    axis === ChartAxis.X || axis === ChartAxis.Y ? [{ id, axis }] : [],
  )
  const config = getForecastConfig({
    addSeries,
    removeSeries,
    chartType: chart.type,
  })

  return (
    <div className="h-[calc(100%-24px)]">
      <DynamicTable
        id={`${factory}-TrendForecasts`}
        data={models}
        config={config}
        selectedRows={selectedScatter.length ? undefined : selected}
        selectedScatter={selectedScatter}
        maxHeightFull
        fuzzySearch
        fuzzySearchField={[
          'name',
          'tag.displayTagName',
          'method.name',
          'state',
        ]}
        shouldAutofocusSearch
      />
    </div>
  )
}

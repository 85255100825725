import {
  Banner,
  Button,
  Spinner,
  Text,
  TimePickerNavigation,
  TimeSeriesChart,
} from 'src/components/ui'
import { TimeSeriesData } from 'src/types'
import { TagDto } from 'src/services'
import { useTagData, useTag } from 'tags/api'

type Props = {
  tagName: string
}

interface TrendChartProps {
  tag: TagDto
  tagData: TimeSeriesData[]
  isPending: boolean
}

const TrendChart = (props: TrendChartProps): JSX.Element => {
  return (
    <TimeSeriesChart
      {...props}
      data={[{ ...props.tag, data: props.tagData }]}
      isPending={props.isPending}
      tooltip
    />
  )
}

export function TagData({ tagName }: Props): JSX.Element {
  return (
    <div className="flex flex-col gap-s rounded-2xs border border-solid border-border bg-background p-[1em] transition-all hover:border-border">
      <div className="flex justify-between">
        <Text variant="title" bold>
          Tag Data
        </Text>
        <TimePickerNavigation />
      </div>
      <Chart tagName={tagName} />
    </div>
  )
}

function Chart({ tagName }: Props): JSX.Element {
  const tagQuery = useTag(tagName)
  const tagDataQuery = useTagData({ id: tagName })

  if (tagQuery.isLoading || tagDataQuery.isLoading) return <Spinner />

  const errorQuery = [tagQuery, tagDataQuery].find(query => query.isError)
  if (errorQuery) {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Try again"
            onClick={() => errorQuery.refetch()}
          />
        }
      >
        Failed to load tag data
      </Banner>
    )
  }

  if (!tagQuery.data)
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Try again"
            onClick={() => tagQuery.refetch()}
          />
        }
      >
        Tag not found
      </Banner>
    )

  return (
    <TrendChart
      tag={tagQuery.data}
      tagData={tagDataQuery.data ?? []}
      isPending={tagQuery.isLoading || tagDataQuery.isLoading}
    />
  )
}

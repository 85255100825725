import { parseISO } from 'date-fns'
import {
  GqlOpcUaSyncTasksFragment,
  GqlOpcUaTaskFragment,
} from '../graphqlTypes'
import { OpcUaSyncTask, OpcUaSyncTasks } from './factory.types'

export function mapApiToOpcUaSyncTasks(
  data: GqlOpcUaSyncTasksFragment,
): OpcUaSyncTasks {
  return {
    ...data,
    lastHierarchySyncTask: data.lastHierarchySyncTask
      ? mapApiToOpcUaSyncTask(data.lastHierarchySyncTask)
      : undefined,
    lastSuccessfulHierarchySyncTask: data.lastSuccessfulHierarchySyncTask
      ? mapApiToOpcUaSyncTask(data.lastSuccessfulHierarchySyncTask)
      : undefined,
  }
}

function mapApiToOpcUaSyncTask(data: GqlOpcUaTaskFragment): OpcUaSyncTask {
  return {
    ...data,
    startTime: parseISO(data.startTime),
    endTime: data.endTime ? parseISO(data.endTime) : undefined,
    lastUpdate: parseISO(data.lastUpdate),
  }
}

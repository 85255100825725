import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Card } from 'src/components/ui'
import { useAuth } from 'src/contexts/auth'

export function MetropolisDashboardCard(): JSX.Element {
  const {
    viewer: { name },
  } = useAuth()

  return (
    <div className="flex flex-col gap-[0.75em] p-[1em]">
      <div className="flex items-center gap-[0.5em] text-text-tertiary">
        <Icon icon={light('chalkboard-user')} size="big" />
        <Text variant="title" bold className="my-m text-2xl">
          Intelecy Metropolis Demo Factory
        </Text>
      </div>
      <div>
        You are now about to start on your Intelecy trial which gives you a
        short but realistic introduction to the Intelecy application.
      </div>
      <div>
        In the trial, you will be working through two cases using real-data and
        focusing on a specific set of features in Intelecy. Furthermore, you’ll
        be working on a scaled down version of the application so some menu
        items (on the left) have been disabled. We have created a manual to
        guide you through a step-by-step process of the cases and how to use
        Intelecy to solve them.
      </div>
      <div>
        Click the link below to open the manual and get started. Good luck!
      </div>
      <div className="text-center">
        <Icon icon={light('chevrons-right')} />
        <a
          href="https://intelecy.notion.site/Intelecy-No-Code-AI-Walkthrough-e82b923fea5c457180b29da7798b7112"
          target="_blank"
          rel="noreferrer"
        >
          <strong>Intelecy No-Code AI Walkthrough</strong>
        </a>
        <Icon icon={light('chevrons-left')} />
      </div>
      <div>
        Want to test Intelecy on your own data? We offer offline and online
        proof of concepts. Contact sales at{' '}
        <a href="mailto:sales@intelecy.com">sales@intelecy.com</a> to get
        started.
      </div>
      <div>
        <em>
          Please note, this is a shared instance. Any models or comments you
          create will be visible to others, and associated with your anonymous
          username <strong>{name}</strong>.
        </em>
      </div>
      <hr />
      <div>
        Please contact{' '}
        <a href="mailto:support@intelecy.com">support@intelecy.com</a> with any
        questions or issues.
      </div>
    </div>
  )
}

import { TagAction } from 'pages/site/models'
import { DynamicTable, Banner, Spinner, Button } from 'src/components/ui'
import { useTags } from 'tags/api'
import { TagDto } from 'src/services'
import { getTagInputListConfig } from 'models/model/table-configs'
import { useSite } from 'src/contexts/site'
import { getInputTagWarnings } from '../../steps.utils'
import { Record } from '../../steps'

interface ModelInputTagsProps {
  model: Record
  inputTags?: string[]
}

export interface WithTagProps extends ModelInputTagsProps {
  tag: TagDto
}

interface DisplayInputTagsProps extends ModelInputTagsProps {
  removeInputTag: (tag: string) => void
}

export function DisplayInputTags({
  inputTags,
  removeInputTag,
  model,
}: DisplayInputTagsProps): JSX.Element {
  const tagsQuery = useTags()

  if (tagsQuery.isLoading) {
    return <Spinner />
  }

  if (tagsQuery.isError) {
    return (
      <Banner
        variant="error"
        className="my-s"
        rightComponent={
          <Button
            variant="primary"
            onClick={() => tagsQuery.refetch()}
            title="Try again"
          />
        }
      >
        Failed to load tags
      </Banner>
    )
  }
  return (
    <DisplayInputTagsWithData
      inputTags={inputTags}
      removeInputTag={removeInputTag}
      model={model}
      tags={tagsQuery.data}
    />
  )
}

type DisplayInputTagsWithDataProps = DisplayInputTagsProps & {
  tags: TagDto[]
}

function DisplayInputTagsWithData({
  inputTags,
  removeInputTag,
  model,
  tags,
}: DisplayInputTagsWithDataProps): JSX.Element {
  const { id: factory, rootLink, viewerRole } = useSite()

  const hasWarning =
    inputTags &&
    inputTags
      .map(tagName => tags.find(tag => tag.tagName === tagName))
      .map(
        tag =>
          tag &&
          getInputTagWarnings(
            viewerRole,
            rootLink,
            tag,
            model.modelType,
            model.outputTag,
          ),
      )
      .some(warnings => warnings && warnings.length > 0)

  const actions = {
    renderAction: ({ data: tag }: any) => (
      <TagAction
        type="Display"
        tag={tag}
        tagAction={removeInputTag}
        modelType={model.modelType}
        modelOutputTagName={model.outputTag}
      />
    ),
  }

  // use flatMap to remove tags not found
  // FIXME maybe this should be an error?
  const displayTags = inputTags
    ? inputTags.flatMap(tagName => {
        const tag = tags.find(tag => tag.tagName === tagName)
        return tag ? [tag] : []
      })
    : []
  return (
    <>
      {hasWarning && (
        <Banner className="mb-xs" variant="warning">
          One or more of the selected tags have warnings.
        </Banner>
      )}

      <div style={{ marginBottom: '0.5em' }}>
        <DynamicTable
          id={`${factory}-DisplayInputTags`}
          data={displayTags.map(t => ({
            ...t,
            displayName: t.displayName || t.tagName,
          }))}
          config={getTagInputListConfig(actions)}
          className="!h-[400px]"
          rowHeight={60}
        />
      </div>
    </>
  )
}

import { Theme } from './theme.types'

export const lightTheme: Theme = {
  background: {
    DEFAULT: '#FFFFFF',
    hover: '#F1F5F9',
    active: '#F3F4F6',
    secondary: {
      DEFAULT: '#F1F5F9',
      hover: '#E2E8F0',
      active: '#CBD5E1',
    },
    tertiary: {
      DEFAULT: '#CBD5E1',
      hover: '#94A3B8',
      active: '#9CA3AF',
    },
    brand: {
      DEFAULT: '#10B981',
      hover: '#059669',
      active: '#059669',
      secondary: {
        DEFAULT: '#A7F3D0',
        hover: '#6EE7B7',
        active: '#6EE7B7',
      },
      tertiary: {
        DEFAULT: '#ECFDF5',
        hover: '#D1FAE5',
        active: '#D1FAE5',
      },
    },
    success: {
      DEFAULT: '#14AE5C',
      hover: '#009951',
      active: '#059669',
      secondary: {
        DEFAULT: '#CFF7D3',
        hover: '#AFF4C6',
        active: '#6EE7B7',
      },
      tertiary: {
        DEFAULT: '#EBFFEE',
        hover: '#CFF7D3',
        active: '#D1FAE5',
      },
    },
    warning: {
      DEFAULT: '#FACC15',
      hover: '#EAB308',
      active: '#F59E0B',
      secondary: {
        DEFAULT: '#FEF08A',
        hover: '#FDE047',
        active: '#FCD34D',
      },
      tertiary: {
        DEFAULT: '#FEFCE8',
        hover: '#FEF9C3',
        active: '#FEF3C7',
      },
    },
    danger: {
      DEFAULT: '#EC221F',
      hover: '#C00F0C',
      active: '#DC2626',
      secondary: {
        DEFAULT: '#FDD3D0',
        hover: '#FCB3AD',
        active: '#FCA5A5',
      },
      tertiary: {
        DEFAULT: '#FEE9E7',
        hover: '#FDD3D0',
        active: '#FECACA',
      },
    },
    disabled: {
      DEFAULT: '#CBD5E1',
      light: '#CBD5E1',
    },
  },
  text: {
    DEFAULT: '#020617',
    secondary: '#64748B',
    tertiary: '#9CA3AF',
    brand: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#FFFFFF',
        secondary: '#064E3B',
        tertiary: '#065F46',
      },
    },
    success: {
      DEFAULT: '#02542D',
      secondary: '#009951',
      tertiary: '#14AE5C',
      onbrand: {
        DEFAULT: '#EBFFEE',
        secondary: '#024023',
        tertiary: '#024023',
      },
    },
    warning: {
      DEFAULT: '#713F12',
      secondary: '#A16207',
      tertiary: '#CA8A04',
      onbrand: {
        DEFAULT: '#422006',
        secondary: '#854D0E',
        tertiary: '#713F12',
      },
    },
    danger: {
      DEFAULT: '#900B09',
      secondary: '#C00F0C',
      tertiary: '#EC221F',
      onbrand: {
        DEFAULT: '#FEE9E7',
        secondary: '#690807',
        tertiary: '#690807',
      },
    },
    disabled: {
      DEFAULT: '#94A3B8',
      ondisabled: '#94A3B8',
    },
  },
  border: {
    DEFAULT: '#E2E8F0',
    secondary: '#64748B',
    tertiary: '#334155',
    brand: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
    },
    success: {
      DEFAULT: '#02542D',
      secondary: '#009951',
      tertiary: '#14AE5C',
    },
    warning: {
      DEFAULT: '#713F12',
      secondary: '#A16207',
      tertiary: '#CA8A04',
    },
    danger: {
      DEFAULT: '#900B09',
      secondary: '#C00F0C',
      tertiary: '#EC221F',
    },
    disabled: '#94A3B8',
  },
  icon: {
    DEFAULT: '#020617',
    secondary: '#64748B',
    tertiary: '#94A3B8',
    brand: {
      DEFAULT: '#065F46',
      secondary: '#059669',
      tertiary: '#10B981',
      onbrand: {
        DEFAULT: '#D1FAE5',
        secondary: '#065F46',
        tertiary: '#064E3B',
      },
    },
    success: {
      DEFAULT: '#02542D',
      secondary: '#009951',
      tertiary: '#14AE5C',
      onbrand: {
        DEFAULT: '#EBFFEE',
        secondary: '#02542D',
        tertiary: '#024023',
      },
    },
    warning: {
      DEFAULT: '#713F12',
      secondary: '#A16207',
      tertiary: '#CA8A04',
      onbrand: {
        DEFAULT: '#422006',
        secondary: '#854D0E',
        tertiary: '#713F12',
      },
    },
    danger: {
      DEFAULT: '#900B09',
      secondary: '#C00F0C',
      tertiary: '#EC221F',
      onbrand: {
        DEFAULT: '#FEE9E7',
        secondary: '#690807',
        tertiary: '#690807',
      },
    },
    disabled: {
      DEFAULT: '#94A3B8',
      ondisabled: '#94A3B8',
    },
  },
}

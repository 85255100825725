import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Text, Tooltip } from 'src/components/ui'

export type Tab = {
  title: string
  path: string
  disabled?: boolean
  tooltipText?: string
}

type TabsListProps = {
  tabs: Tab[]
}
export function RoutingTabs({ tabs }: TabsListProps): JSX.Element {
  function handleClickedTab(e: any, path: string): void {
    const clickedTab = tabs.find(tab => tab.path === path)
    if (clickedTab && clickedTab.disabled) {
      e.preventDefault()
    }
  }
  return (
    <div className="flex justify-start">
      {tabs.map(tab => (
        <NavLink
          key={tab.path}
          onClick={e => handleClickedTab(e, tab.path)}
          end
          to={`${tab.path}`}
          className={({ isActive }) =>
            classNames(
              'border-0 border-solid px-s text-black no-underline',
              isActive
                ? 'border-b-[2px] border-border-brand'
                : 'border-b-[1px] border-border-secondary',
              tab.disabled && 'cursor-default text-text-tertiary',
            )
          }
        >
          {({ isActive }) => (
            <Tooltip
              isOpen={!tab.disabled || !tab.tooltipText ? false : undefined}
              direction="bottom"
              render={() => {
                return <Text>{tab.tooltipText}</Text>
              }}
            >
              <Text variant="content-rubik" bold={isActive} className="!mb-xs">
                {tab.title}
              </Text>
            </Tooltip>
          )}
        </NavLink>
      ))}
    </div>
  )
}

import { Navigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useTag, useTagGql, useUpdateTagMutation } from 'tags/api'
import {
  Text,
  TextInput,
  Spinner,
  EditableField,
  SelectInput,
} from 'src/components/ui'
import { humanDateTime } from 'src/utility/time'
import { useSite } from 'src/contexts/site'
import { SiteRole } from 'src/types'
import {
  GqlTagClassification,
  tagLabelOptions,
  tagLabelToText,
} from 'src/services'

type Props = {
  tagName: string
}

enum UpdatingTagProperty {
  DISPLAY_NAME = 'displayName',
  DESCRIPTION = 'description',
  UNIT = 'unit',
  LABEL = 'label',
}

export function TagOverview({ tagName }: Props): JSX.Element {
  const { data: tag, isLoading: isPending } = useTag(tagName)
  // This is only to get the available time range because it's not included in the REST API
  const { data: tagData } = useTagGql(tagName)
  const [updatingTagProperty, setUpdatingTagProperty] =
    useState<UpdatingTagProperty | null>(null)

  const { viewerRole: role } = useSite()

  const updateTagMutation = useUpdateTagMutation()

  if (!tag && !isPending) {
    toast.error(`Tag ${tagName} Not Found`)
    return <Navigate to="/assets" />
  }

  const label = tag?.overriddenLabel ?? tag?.automaticLabel
  return (
    <div className="rounded-2xs border border-solid border-border bg-background p-[1em] transition-all hover:border-border">
      {tag && !isPending ? (
        <>
          <EditableField
            initialState={tag.displayName ?? tag.tagName}
            renderDisplay={() => (
              <Text variant="title" bold className="!text-3xl">
                {tag.displayName ?? tag.tagName}
              </Text>
            )}
            renderInput={({ state, setState }) => (
              <TextInput
                value={state}
                onChange={e => setState(e.target.value)}
                containerStyles="!py-0"
                inputStyles="!text-2xl "
              />
            )}
            updateStatus={
              updateTagMutation.isLoading &&
              updatingTagProperty === UpdatingTagProperty.DISPLAY_NAME
                ? 'loading'
                : 'success'
            }
            onSave={async state => {
              setUpdatingTagProperty(UpdatingTagProperty.DISPLAY_NAME)
              await updateTagMutation.mutateAsync(
                {
                  tagId: tag.tagNodeId,
                  displayName: state,
                },
                {
                  onError: () => {
                    toast.error('Failed to update tag name', {
                      position: 'top-right',
                    })
                  },
                },
              )
              setUpdatingTagProperty(null)
            }}
            isEditable={role === SiteRole.ADMIN}
            saveDisabled={state => state === (tag.displayName ?? tag.tagName)}
          />
          <Text className="my-2xs mt-s">ID: {tag.tagNodeId}</Text>
          <div className="flex items-center gap-2xs">
            <Text>Unit: </Text>
            <EditableField
              initialState={tag.engUnit}
              renderDisplay={() => <Text>{tag.engUnit || '/'}</Text>}
              renderInput={({ state, setState }) => (
                <TextInput
                  value={state ?? ''}
                  onChange={e => setState(e.target.value)}
                />
              )}
              updateStatus={
                updateTagMutation.isLoading &&
                updatingTagProperty === UpdatingTagProperty.UNIT
                  ? 'loading'
                  : 'success'
              }
              onSave={async state => {
                setUpdatingTagProperty(UpdatingTagProperty.UNIT)
                await updateTagMutation.mutateAsync(
                  {
                    tagId: tag.tagNodeId,
                    engUnit: state,
                  },
                  {
                    onError: () => {
                      toast.error('Failed to update tag unit', {
                        position: 'top-right',
                      })
                    },
                  },
                )
                setUpdatingTagProperty(null)
              }}
              isEditable={role === SiteRole.ADMIN}
              saveDisabled={state => state === tag.engUnit}
            />
          </div>
          <div className="flex items-center gap-2xs">
            <Text>Description: </Text>
            <EditableField
              initialState={tag.description}
              renderDisplay={() => <Text>{tag.description || '/'}</Text>}
              renderInput={({ state, setState }) => (
                <TextInput
                  value={state ?? ''}
                  onChange={e => setState(e.target.value)}
                />
              )}
              updateStatus={
                updateTagMutation.isLoading &&
                updatingTagProperty === UpdatingTagProperty.DESCRIPTION
                  ? 'loading'
                  : 'success'
              }
              onSave={async state => {
                setUpdatingTagProperty(UpdatingTagProperty.DESCRIPTION)
                await updateTagMutation.mutateAsync(
                  {
                    tagId: tag.tagNodeId,
                    description: state,
                  },
                  {
                    onError: () => {
                      toast.error('Failed to update tag description', {
                        position: 'top-right',
                      })
                    },
                  },
                )
                setUpdatingTagProperty(null)
              }}
              isEditable={role === SiteRole.ADMIN}
              saveDisabled={state => state === tag.description}
            />
          </div>
          <div className="flex items-center gap-2xs">
            <Text>Label: </Text>
            <EditableField
              initialState={label}
              renderDisplay={() => (
                <Text>{label ? tagLabelToText(label) : '/'}</Text>
              )}
              updateStatus={
                updateTagMutation.isLoading &&
                updatingTagProperty === UpdatingTagProperty.LABEL
                  ? 'loading'
                  : 'success'
              }
              renderInput={({ state, setState }) => {
                const selectedOption = tagLabelOptions.find(
                  o => o.value === state,
                )
                return (
                  <SelectInput
                    className="min-w-[150px]"
                    value={selectedOption}
                    options={tagLabelOptions}
                    onChange={v => setState(v as GqlTagClassification)}
                  />
                )
              }}
              isEditable={role === SiteRole.ADMIN}
              saveDisabled={state => state === label}
              onSave={async state => {
                const updatedLabel = tagLabelOptions.find(
                  o => o.value === state,
                )
                if (!updatedLabel) return
                setUpdatingTagProperty(UpdatingTagProperty.LABEL)
                await updateTagMutation.mutateAsync(
                  {
                    tagId: tag.tagNodeId,
                    overriddenLabel: updatedLabel.value,
                  },
                  {
                    onError: () => {
                      toast.error('Failed to update tag label', {
                        position: 'top-right',
                      })
                    },
                  },
                )
                setUpdatingTagProperty(null)
              }}
            />
          </div>
          {tagData?.availableDataTimeRange && (
            <>
              <Text className="mt-xs">
                First Data Point:{' '}
                {humanDateTime(tagData.availableDataTimeRange.min)}
              </Text>
              <Text className="mt-xs">
                Last Data Point:{' '}
                {humanDateTime(tagData.availableDataTimeRange.max)}
              </Text>
            </>
          )}
          <Text className="mt-xs">
            Parent Name: <span>{tag.parentName ?? 'Unassigned'}</span>
          </Text>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'

type Props = {
  tagName: string
}
export function TagMenu({ tagName }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useClickOutside(dropdownRef, () => {
    setIsOpen(false)
  })

  const handleCopyNodeId = (): void => {
    setIsOpen(false)
    navigator.clipboard.writeText(tagName)
    toast.success('Node ID copied to clipboard')
  }

  return (
    <div
      className="relative inline-block"
      style={{ zIndex: 999999999 }}
      ref={dropdownRef}
    >
      <Icon
        size="regular"
        icon={regular('ellipsis')}
        onClick={() => setIsOpen(!isOpen)}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0, dur: 0.1 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              opacity: { duration: 0.1 },
              y: { duration: 0.1 },
            }}
            className="absolute right-0 top-[80%] rounded-2xs bg-background shadow-dropdown"
          >
            <div
              className="flex cursor-pointer items-center gap-2xs p-xs transition hover:bg-background-hover"
              onClick={handleCopyNodeId}
            >
              <Text variant="description" bold>
                Copy Node ID
              </Text>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

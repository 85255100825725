import { useParams } from 'react-router-dom'
import { Spinner } from 'src/components/ui'
import { ErrorDisplay } from 'pages/app'
import {
  OpcUaHierarchyTree,
  OpcUaHieryrchySyncStatus,
} from './opc-ua-hieryrchy'
import { useOpcSyncTasks } from './opc-ua-connection.api'

export function OpcUaHierarchyTab(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId)
    throw new Error('Missing orgId, siteId, gatewayId or connectionId')

  const connectionSyncQuery = useOpcSyncTasks(connectionId)

  if (connectionSyncQuery.isLoading) return <Spinner />

  if (connectionSyncQuery.isError) {
    return (
      <ErrorDisplay
        error={connectionSyncQuery.error}
        message="Failed to fetch connection sync status"
        action={connectionSyncQuery.refetch}
      />
    )
  }

  return (
    <div className="flex flex-col gap-m py-s">
      <OpcUaHieryrchySyncStatus tasks={connectionSyncQuery.data} />
      <OpcUaHierarchyTree siteId={siteId} connectionId={connectionId} />
    </div>
  )
}

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  milliseconds,
} from 'date-fns'
import { TimeRangeOrPeriod, isTimeRange } from 'src/types'
import { LabelValue } from './TimePickerInput'

function plural(value: number, unit: string): string {
  if (value === 1) {
    return `1 ${unit}`
  }
  return `${value} ${unit}s`
}

export const rangeSummary = (
  value: TimeRangeOrPeriod,
  options: LabelValue[],
): { rangeSummary: string; rangeDescription: string } => {
  if (isTimeRange(value)) {
    const years = differenceInYears(value.to + 10, value.from)
    if (years > 0) {
      return {
        rangeSummary: `${years}y`,
        rangeDescription: `Past ${plural(years, 'Year')}`,
      }
    }

    const months = differenceInMonths(value.to + 10, value.from)
    if (months > 0) {
      return {
        rangeSummary: `${months}mo`,
        rangeDescription: `Past ${plural(months, 'Month')}`,
      }
    }

    const days = differenceInDays(value.to + 10, value.from)
    if (days > 0) {
      // check for week
      const diff = value.to - value.from + 10
      const week = milliseconds({ weeks: 1 })
      if (diff > week - 20 && diff < week + 20) {
        return {
          rangeSummary: `1w`,
          rangeDescription: `Past 1 Week`,
        }
      }
      return {
        rangeSummary: `${days}d`,
        rangeDescription: `Past ${plural(days, 'Day')}`,
      }
    }

    const hours = differenceInHours(value.to + 10, value.from)
    if (hours > 0) {
      return {
        rangeSummary: `${hours}h`,
        rangeDescription: `Past ${plural(hours, 'Hour')}`,
      }
    }

    const minutes = differenceInMinutes(value.to + 10, value.from)
    if (minutes > 0) {
      return {
        rangeSummary: `${minutes}m`,
        rangeDescription: `Past ${plural(minutes, 'Minute')}`,
      }
    }

    return { rangeSummary: '-', rangeDescription: 'Past 1 Minute' }
  }
  const option = options.find(option => option.value === value.period)
  return {
    rangeSummary: option?.summaryLabel || '-',
    rangeDescription: option?.label || '-',
  }
}

import { TimeRangeProvider } from 'src/contexts/timeRange'
import { useTag, useTagData } from 'tags/api'
import {
  Text,
  Button,
  Banner,
  Card,
  TimePickerNavigation,
  TimeSeriesChart,
} from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { getOutputTagWarnings } from '../../steps.utils'

interface TrendChartProps {
  id: string
}

const TrendChart = (props: TrendChartProps): JSX.Element => {
  const { data: tag, isLoading } = useTag(props.id)
  const tagData = useTagData(props)

  return (
    <TimeSeriesChart
      {...props}
      data={[{ ...tag, data: tagData.data }]}
      isPending={isLoading || tagData.isLoading}
      tooltip
    />
  )
}

interface DisplayOutputTagProps {
  outputTag: string
  setOutputTag: (tag: string | null) => void
}

export function DisplayOutputTag({
  outputTag,
  setOutputTag,
}: DisplayOutputTagProps): JSX.Element {
  const { rootLink, viewerRole } = useSite()
  const { data: tag } = useTag(outputTag ?? '')
  const messages = getOutputTagWarnings(viewerRole, rootLink, tag)
  return (
    <TimeRangeProvider>
      {tag && messages.length > 0 && (
        <Banner variant="warning" className="mb-xs">
          {messages.map((message, i) => (
            <div key={i}>
              <Text bold>{message}</Text>
            </div>
          ))}
        </Banner>
      )}
      <Card className="mb-[0.5em]">
        <div className="grid grid-cols-[auto_auto_1fr] items-center gap-4">
          <div>
            <Text>{tag?.displayName ?? tag?.tagName}</Text>
            <Text className="text-text-tertiary">
              {tag?.engUnit && tag?.engUnit.toLowerCase() !== 'none'
                ? `${tag?.description} (${tag?.engUnit})`
                : tag?.description}
            </Text>
          </div>
          <Button
            variant="secondary"
            title="Change"
            onClick={() => setOutputTag(null)}
          />
          <TimePickerNavigation />
        </div>
        <TrendChart id={outputTag} />
      </Card>
    </TimeRangeProvider>
  )
}

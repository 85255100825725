import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { Asset } from 'src/types'
import { FETCH_TAGS_QUERY } from 'tags/api'
import { ASSETS_QUERY_KEY } from './assets.get'

export function useUpdateAssetMutation(): UseMutationResult<
  void,
  unknown,
  api.UpdateAssetDto
> {
  const queryClient = useQueryClient()
  const { id: currentFactory } = useSite()

  return useMutation({
    mutationFn: async (input: api.UpdateAssetDto) => {
      await api.updateAsset(input)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ASSETS_QUERY_KEY, currentFactory])
      queryClient.invalidateQueries([FETCH_TAGS_QUERY, currentFactory])
    },
  })
}

export function useUpdateHierarchyAssetsMutation(): UseMutationResult<
  api.UpdateHierarchyAssetDto[],
  unknown,
  Asset[]
> {
  const { id: currentFactory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (assets: Asset[]) => {
      const updates: api.UpdateHierarchyAssetDto[] = assets.map(a => ({
        ...a,
        viewType: 0,
        id: a.hierarchyId,
      }))

      const successful = []

      for (const update of updates) {
        try {
          await api.updateHierarchyAsset(update)
          successful.push(update)
        } catch (error) {
          // FIXME we ignore failed for now
        }
      }

      return successful
    },
    onSuccess: () =>
      queryClient.invalidateQueries([ASSETS_QUERY_KEY, currentFactory]),
  })
}

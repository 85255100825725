import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Icon, Text, Tooltip } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { useAddStarMutation, useRemoveStarMutation } from 'models/api'
import { FetchModel } from 'src/services'
import { useSite } from 'src/contexts/site'
import { isModelEditing } from '../modelOverview.state'

type DropdownItemProps = {
  icon: IconProp
  text: string
  onClick: () => void
  disabled?: boolean
}

function DropdownItem({
  icon,
  text,
  disabled,
  onClick,
}: DropdownItemProps): JSX.Element {
  return (
    <button
      className="flex min-w-[113px] cursor-pointer items-center gap-xs border-0 bg-background transition duration-200 hover:bg-background-hover disabled:cursor-default disabled:hover:bg-background"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon icon={icon} className="w-icon-small" />
      <Text variant="description" bold>
        {text}
      </Text>
    </button>
  )
}

type Props = {
  model: FetchModel
}

export function Menu({ model }: Props): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const { id: factory } = useSite()
  const addStar = useAddStarMutation(factory)
  const removeStar = useRemoveStarMutation(factory)

  useClickOutside(menuRef, () => {
    setIsMenuOpen(false)
  })

  return (
    <div className="relative" ref={menuRef}>
      <Tooltip
        containerClassName="!flex w-full h-full !items-center !justify-center"
        className="!p-xs"
        render={() => (
          <Text variant="description">Edit, Favorite, Copy ID</Text>
        )}
      >
        <Button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          icon={regular('ellipsis')}
          variant="icon"
        />
      </Tooltip>
      {isMenuOpen && (
        <div
          className="absolute right-0 top-[calc(100%+8px)] flex cursor-default flex-col gap-xs rounded-2xs bg-background p-xs shadow-button"
          onClick={e => e.stopPropagation()}
        >
          <DropdownItem
            icon={light('star')}
            disabled={addStar.isLoading || removeStar.isLoading}
            text={model.viewerHasStarred ? 'Unfavorite' : 'Favorite'}
            onClick={async () => {
              if (!model.viewerHasStarred) {
                await addStar.mutateAsync(
                  {
                    starrableId: model.id,
                  },
                  {
                    onError: () => {
                      toast.error('Failed to favorite model', {
                        position: 'top-right',
                      })
                    },
                  },
                )
              } else {
                await removeStar.mutateAsync(
                  {
                    starrableId: model.id,
                  },
                  {
                    onError: () => {
                      toast.error('Failed to unfavorite model', {
                        position: 'top-right',
                      })
                    },
                  },
                )
              }
              setIsMenuOpen(false)
            }}
          />
          <div className="h-px w-full bg-background-disabled" />
          <DropdownItem
            icon={light('copy')}
            text="Copy Model ID"
            onClick={async () => {
              await navigator.clipboard.writeText(model.id)
              toast.success(
                <Text variant="description" bold>
                  <span className="text-text-success-secondary">
                    {model.id}
                  </span>{' '}
                  Successfully copied to clipboard
                </Text>,
              )
              setIsMenuOpen(false)
            }}
          />
          <div className="h-px w-full bg-background-disabled" />

          <DropdownItem
            icon={light('pen-circle')}
            text="Edit"
            onClick={() => {
              isModelEditing.value = true
              setIsMenuOpen(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

import { GqlOpcUaSyncTaskState, OpcUaSyncTasks } from 'src/services'
import {
  CompletedSyncContent,
  ErrorSyncContent,
  InProgressSyncContent,
} from './opc-ua-hierarchy-sync-status'

type Props = {
  tasks: OpcUaSyncTasks
}

export function OpcUaHieryrchySyncStatus({ tasks }: Props): JSX.Element {
  if (tasks.lastHierarchySyncTask?.state === GqlOpcUaSyncTaskState.Failed) {
    return (
      <ErrorSyncContent
        failedSyncTime={tasks.lastHierarchySyncTask.endTime}
        latestSuccessfulSyncTime={
          tasks.lastSuccessfulHierarchySyncTask?.endTime
        }
        error={tasks.lastHierarchySyncTask.error ?? 'Failed to sync hierarchy'}
      />
    )
  }

  if (tasks.lastHierarchySyncTask?.state === GqlOpcUaSyncTaskState.Successful) {
    return <CompletedSyncContent latestSyncTask={tasks.lastHierarchySyncTask} />
  }

  if (tasks.lastHierarchySyncTask?.state === GqlOpcUaSyncTaskState.Running) {
    return <InProgressSyncContent tasks={tasks} />
  }

  return <></>
}

import { useEffect, useRef, useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { merge } from 'lodash'
import { useDrop } from 'react-dnd'
import classNames from 'classnames'
import { datadogRum } from '@datadog/browser-rum'
import { Icon, Text, Tooltip, ScatterChart } from 'src/components/ui'
import { hasSeries, removeSeries, useCharts } from 'src/contexts/charts'
import { ChartType } from 'src/types/chartTypes'
import { DnDItem } from '../../trend.types'
import { AddChartModal } from '../Modals'
import { TimeSeriesChart, Legend, ScatterLegend, ChartName } from './components'
import useChartData from './useChartData'

interface ChartProps {
  chartId: number
}

export function Chart({ chartId }: ChartProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { charts, setChart, removeChart } = useCharts()
  const chartRef = useRef(charts)
  const chart = charts.find(c => c.id === chartId)
  if (!chart) {
    throw new Error(`Chart with id ${chartId} not found`)
  }
  const [chartData, isPending] = useChartData(chart)
  const [hoverIndex, setHoverIndex] = useState<number>()

  const [{ canDrop, isOver, item }, dropRef] = useDrop(() => ({
    accept: 'legend-row',
    drop: handleDrop,
    canDrop: item => {
      // if we have no item, we can't drop
      if (!item) {
        return false
      }
      const targetChart = chartRef.current.find(c => c.id === chartId)

      // if we didn't find the chart or the chart is not time series, we can't drop
      if (!targetChart || targetChart.type !== ChartType.TimeSeries) {
        return false
      }
      // we can't drop if the chart already contains the series
      return !hasSeries(targetChart, item.series)
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
      item: monitor.getItem(),
    }),
  }))

  // Update the chartRef when the charts change
  // To have valid id for the next chart
  useEffect(() => {
    chartRef.current = charts
  }, [charts])

  function handleDrop(props: DnDItem): void {
    // Remove item from the source chart
    const sourceChart = chartRef.current.find(c => c.id === props.sourceChartId)

    const targetChart = chartRef.current.find(c => c.id === chartId)
    if (!targetChart) {
      return
    }

    if (sourceChart) {
      const newChartConfig = removeSeries(sourceChart, props.series)
      if (newChartConfig.data.length === 0) removeChart(sourceChart.id)
      else setChart(sourceChart.id, newChartConfig)
    }

    // Add the item to the chart
    setChart(targetChart.id, {
      ...targetChart,
      data: [...targetChart.data, props.series],
    })
    datadogRum.addAction('Series dragged between charts', {
      sourceChart: props.sourceChartId,
      targetChart: targetChart.id,
      draggedSeries: props.series,
    })
  }

  const isActive = canDrop && isOver

  const shouldBeDisabled =
    item?.series &&
    hasSeries(chart, item.series) &&
    chart.type !== ChartType.TimeSeries

  const getBorderClass = (): string => {
    if (isActive && !shouldBeDisabled) {
      return 'border-dashed border-border-brand'
    }
    if (isOver && !canDrop && item.sourceChartId !== chart.id) {
      return 'border-dashed border-border-danger'
    }
    return 'border-solid border-border'
  }

  return (
    <div
      ref={dropRef}
      id={`chart-${chart.id}`}
      className={classNames(
        'grid h-full w-full grid-cols-[minmax(0,1fr)] grid-rows-[auto_minmax(0,1fr)] gap-[0.2em] overflow-hidden rounded-2xs border bg-background',
        getBorderClass(),
      )}
    >
      <div className="flex items-center justify-between gap-xs px-xs pt-xs">
        <div className="flex max-w-[calc(100%-48px)] flex-1 items-center gap-s">
          <Icon
            icon={light('arrows-up-down-left-right')}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="drag-handle cursor-move"
          />
          <ChartName chart={chart} />
        </div>
        <div className="flex items-center gap-xs">
          <Tooltip render={() => <Text>Edit chart data</Text>}>
            <Icon
              icon={light('pen-circle')}
              className="cursor-pointer text-icon-success-secondary"
              size="regular"
              onClick={() => setIsOpen(true)}
            />
          </Tooltip>
          <Tooltip render={() => <Text>Remove chart</Text>}>
            <Icon
              icon={light('circle-xmark')}
              className="cursor-pointer"
              size="regular"
              onClick={() => removeChart(chart.id)}
            />
          </Tooltip>
        </div>
      </div>
      {chart.type === ChartType.TimeSeries ? (
        <>
          <TimeSeriesChart
            data={chartData}
            chart={chart}
            hoverIndex={hoverIndex}
            isPending={isPending}
          />
          <Legend
            chart={chart}
            setChart={o => setChart(chart.id, o)}
            data={chartData}
            isDataPending={isPending}
            remove={index => {
              const data = chart.data.filter((_, i) => i !== index)
              if (data.length > 0) {
                setChart(chart.id, { ...chart, data })
              } else {
                removeChart(chart.id)
              }
            }}
            setOptions={(i, options) => {
              setChart(chart.id, {
                ...chart,
                data: chart.data.map((opt, index) => {
                  if (index === i) {
                    const mergedOpt = merge({ ...opt }, options)
                    if (
                      Object.keys(options).includes('min') &&
                      options.min === undefined
                    )
                      mergedOpt.min = undefined
                    if (
                      Object.keys(options).includes('max') &&
                      options.max === undefined
                    )
                      mergedOpt.max = undefined
                    return mergedOpt
                  }
                  return opt
                }),
              })
            }}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
          />
        </>
      ) : (
        <>
          <ScatterChart
            colorIndex={chart.colorIndex ?? chart.id}
            chartData_x={chartData[chart.data.findIndex(d => d.axis === 'x')]}
            chartData_y={chartData[chart.data.findIndex(d => d.axis === 'y')]}
            isPending={isPending}
          />
          <ScatterLegend
            chart={chart}
            data={chartData}
            isDataPending={isPending}
            readOnly
            setChart={o => setChart(chart.id, o)}
          />
        </>
      )}
      {isOpen && (
        <AddChartModal
          isEdit
          chart={chart}
          close={() => setIsOpen(false)}
          onAddChart={c => setChart(chart.id, c)}
        />
      )}
    </div>
  )
}

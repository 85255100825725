import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Text } from 'src/components/ui'
import { useSite } from 'src/contexts/site'

export const NoFavoriteModels = memo(function NoFavoriteModels(): JSX.Element {
  const { rootLink } = useSite()
  const navigate = useNavigate()

  function handleFavoriteModel(): void {
    navigate(`${rootLink}/models`)
  }

  return (
    <div className="flex flex-col items-center gap-s text-center">
      <div className="flex flex-col gap-3xs">
        <Text bold variant="title" className="!text-medium">
          Anomaly Distribution
        </Text>
        <Text className="text-text-tertiary">
          You do not have any favorite models.
        </Text>
      </div>
      <Button
        variant="secondary"
        title="Set Favorite Model"
        onClick={handleFavoriteModel}
      />
    </div>
  )
})

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { formatISO, subDays } from 'date-fns'
import { memo, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Icon, ProgressBar, Text } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { AnomalyBase, GqlAnomalyState } from 'src/services'

type Props = {
  anomalies: AnomalyBase[]
}

export const AnomalyDistribution = memo(function AnomalyDistribution({
  anomalies,
}: Props): JSX.Element {
  const { rootLink } = useSite()
  const navigate = useNavigate()

  function handleReviewAnomalies(): void {
    const to = new Date()
    const from = subDays(to, 7)
    const searchParams = new URLSearchParams()
    searchParams.set('from', formatISO(from))
    searchParams.set('to', formatISO(to))
    searchParams.set('favoriteModels', 'true')
    const url = `${rootLink}/anomalies?${searchParams.toString()}`
    navigate(url.toString())
  }

  const unreviewedAnomalies = useMemo(
    () =>
      anomalies.filter(a => {
        const needReviewStates: GqlAnomalyState[] = [
          GqlAnomalyState.InProgress,
          GqlAnomalyState.New,
        ]
        return needReviewStates.includes(a.state as GqlAnomalyState)
      }),
    [anomalies],
  )

  const confirmedAnomalies = useMemo(
    () => anomalies.filter(a => a.state === GqlAnomalyState.Anomaly),
    [anomalies],
  )

  const notAnomalies = useMemo(
    () => anomalies.filter(a => a.state === GqlAnomalyState.NotAnomaly),
    [anomalies],
  )

  const uncertianAnomalies = useMemo(
    () => anomalies.filter(a => a.state === GqlAnomalyState.Uncertain),
    [anomalies],
  )

  const totalReviewed =
    confirmedAnomalies.length + notAnomalies.length + uncertianAnomalies.length

  return (
    <>
      <div className="flex flex-col gap-3xs">
        <Text bold variant="title" className="!text-medium">
          Anomaly Distribution
        </Text>
        <Text className="text-text-tertiary">
          Last 7 days from{' '}
          <Link
            to={`${rootLink}/models?starred=true`}
            className="text-text-success-secondary no-underline"
          >
            favorite models
          </Link>
        </Text>
      </div>
      <div className="mt-m flex w-full items-center justify-between">
        <div className="flex items-center gap-2xs">
          <Text className="!text-large !font-700">
            {unreviewedAnomalies.length}
          </Text>
          <Text bold>
            Need Review
            {unreviewedAnomalies.length === 0 && <span>. Well done!</span>}
          </Text>
        </div>
        <Text
          bold
          variant="description"
          className="hidden text-text-tertiary @md:block"
        >
          {totalReviewed} Reviewed
        </Text>
      </div>
      <ProgressBar
        value={
          totalReviewed + unreviewedAnomalies.length === 0
            ? 0
            : (unreviewedAnomalies.length /
                (totalReviewed + unreviewedAnomalies.length)) *
              100
        }
        className="mt-s"
        indicatorClassName="!bg-text-warning"
      />
      <div className="mt-s flex flex-wrap items-start justify-between gap-xs">
        <div className="hidden items-center gap-s @md:flex">
          <div className="flex items-center gap-2xs">
            <Text variant="description" bold>
              {confirmedAnomalies.length}
            </Text>
            <Text variant="description">Is Anomaly</Text>
            <Icon
              size="xsmall"
              icon={regular('user-check')}
              className="h-[9px]"
            />
          </div>
          <div className="flex items-center gap-2xs">
            <Text variant="description" bold>
              {notAnomalies.length}
            </Text>
            <Text variant="description">Is not Anomaly</Text>
            <Icon
              size="xsmall"
              icon={regular('user-xmark')}
              className="h-[9px]"
            />
          </div>
          <div className="flex items-center gap-2xs">
            <Text variant="description" bold>
              {uncertianAnomalies.length}
            </Text>
            <Text variant="description">Uncertain</Text>
            <Icon
              size="xsmall"
              icon={regular('user-minus')}
              className="h-[9px]"
            />
          </div>
        </div>
        {unreviewedAnomalies.length > 0 && (
          <Button
            variant="primary"
            title="Review Anomalies"
            onClick={handleReviewAnomalies}
          />
        )}
      </div>
    </>
  )
})

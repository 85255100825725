import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon, Text, Tooltip } from 'src/components/ui'
import { OpcUaSyncTask } from 'src/services'
import { humanDateTime } from 'src/utility/time'
import { OpcUaHierarchySyncLayout } from './OpcUaHierarchySyncLayout'

type Props = {
  latestSyncTask: OpcUaSyncTask
}

export function CompletedSyncContent({ latestSyncTask }: Props): JSX.Element {
  return (
    <OpcUaHierarchySyncLayout
      header={{
        text: latestSyncTask.endTime
          ? `OPC-UA hierarchy was successfully synchronized at ${humanDateTime(
              latestSyncTask.endTime,
            )}`
          : 'OPC-UA hierarchy was successfully synchronized',
        icon: light('circle-check'),
        iconClassName: 'text-icon-success',
      }}
      content={
        <div className="flex items-center gap-l pl-[20px]">
          <Text>
            <span className="font-500">Nodes found:</span>{' '}
            <span>{latestSyncTask.nodesSeen}</span>
          </Text>
          <div className="flex items-center gap-2xs">
            <Text>
              <span className="font-500">Errors encountered:</span>{' '}
              <span>{latestSyncTask.browseErrors}</span>
            </Text>
            <Tooltip
              direction="bottom"
              containerClassName="flex items-center"
              render={() => (
                <Text>
                  Errors encountered while trying to browse OPC-UA nodes.
                </Text>
              )}
            >
              <Icon
                icon={light('circle-question')}
                className="text-icon-tertiary"
              />
            </Tooltip>
          </div>
        </div>
      }
    />
  )
}

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useClickOutside } from 'src/utility'
import { Icon } from '../../icons'
import { Text } from '../../typography'

type TextInputProps = {
  name?: string
  variant?: 'outlined' | 'underlined'
  label?: string
  labelVariant?: 'small' | 'description'
  summaryText?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isError?: boolean
  placeholder?: string
  iconRight?: IconProp
  iconLeft?: IconProp
  onIconRightClick?: () => void
  onIconLeftClick?: () => void
  disabled?: boolean
  className?: string
  containerStyles?: string
  inputStyles?: string
  setFocus?: (val: boolean) => void
  onClickOutside?: () => void
  type?: string
  autofocus?: boolean
}

const TextInput = ({
  name,
  variant = 'outlined',
  placeholder,
  label,
  labelVariant = 'small',
  summaryText,
  value,
  onChange,
  isError,
  iconRight,
  iconLeft,
  onIconRightClick,
  onIconLeftClick,
  disabled,
  className,
  containerStyles,
  inputStyles,
  setFocus,
  onClickOutside,
  type = 'text',
  autofocus,
}: TextInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autofocus, inputRef])

  useClickOutside(inputRef, () => onClickOutside?.())

  return (
    <div className={classNames('flex flex-col gap-2xs', className)}>
      {label && (
        <label>
          <Text
            variant={labelVariant}
            bold
            className={classNames(
              disabled && 'text-text-disabled',
              isError && 'text-text-danger',
            )}
          >
            {label}
          </Text>
        </label>
      )}
      <div
        role="text-container"
        className={classNames(
          'flex items-center justify-between gap-2xs',
          variant === 'outlined'
            ? `w-full rounded-2xs border border-solid border-border p-xs transition-all duration-300 ${
                disabled
                  ? 'bg-background-disabled-light'
                  : 'bg-background hover:border-border-brand-tertiary focus-within:hover:border-border-success-secondary focus-within:border-border-success-secondary focus-within:shadow-input hover:shadow-input'
              } ${isError && '!border-border-danger'}`
            : `w-full border-0 border-b border-solid border-border-secondary py-xs ${
                disabled
                  ? 'border-border-disabled'
                  : `hover:border-border-brand-tertiary focus-within:hover:border-border-success-secondary focus-within:border-border-success-secondary ${
                      isError && '!border-border-danger'
                    }`
              }`,
          containerStyles,
        )}
      >
        {iconLeft && (
          <button
            onClick={onIconLeftClick}
            data-testid="left-icon"
            className="border-0 bg-transparent p-0"
          >
            <Icon
              icon={iconLeft}
              className={`cursor-pointer ${disabled && 'text-icon-secondary'}`}
            />
          </button>
        )}
        <input
          ref={inputRef}
          name={name}
          role="text-input"
          className={classNames(
            'flex w-full min-w-0 appearance-none border-none bg-transparent text-description leading-[14px] font-500 placeholder:font-normal disabled:text-text-disabled disabled:placeholder:text-text-disabled',
            isError && 'text-text-danger-secondary',
            inputStyles,
          )}
          value={value}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onFocus={() => setFocus && setFocus(true)}
          onBlur={() => setFocus && setFocus(false)}
        />
        {iconRight && (
          <button
            onClick={onIconRightClick}
            data-testid="right-icon"
            className="border-0 bg-transparent p-0"
          >
            <Icon
              icon={iconRight}
              className={`cursor-pointer ${disabled && 'text-icon-disabled'}`}
            />
          </button>
        )}
      </div>
      {summaryText && (
        <Text
          variant="small"
          className={classNames(
            '!leading-[14px]',
            disabled && 'text-text-disabled',
          )}
        >
          {summaryText}
        </Text>
      )}
    </div>
  )
}
export default TextInput

import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Button,
  Icon,
  NumberInput,
  SelectInput,
  Text,
  Tooltip,
} from 'src/components/ui'
import { FetchAnomalyModel, GqlAnomalyConditionsFragment } from 'src/services'
import { isThresholdSameAsOption } from '../util'
import { thresholdOptions } from './defaultThresholds'
import { AnomalyThresholdInput } from './AnomalyThresholdInput'

type Props = {
  model: FetchAnomalyModel
  thresholds?: GqlAnomalyConditionsFragment[]
  onCancel?: () => void
  onSubmit?: (threshold: GqlAnomalyConditionsFragment) => void
  onDelete?: () => void
  isEdit?: boolean
  initialThreshold?: GqlAnomalyConditionsFragment
  readOnly?: boolean
}

export function AddAnomalyThreshold({
  model,
  thresholds,
  onCancel,
  onSubmit,
  onDelete,
  isEdit = false,
  initialThreshold,
  readOnly,
}: Props): JSX.Element {
  const getFilteredOptions = useCallback(() => {
    return thresholdOptions.filter(o => {
      const matchAvailableScores = !!model.availableErrorScores?.find(
        e => e.kind === o.errorScoreKind && e.transform === o.transform,
      )
      const thresholdMatch = thresholds?.find(t =>
        isThresholdSameAsOption(t, o),
      )
      const editConditions =
        isEdit &&
        initialThreshold &&
        isThresholdSameAsOption(initialThreshold, o)
      return (
        readOnly ||
        (matchAvailableScores && (!thresholdMatch || editConditions))
      )
    })
  }, [
    readOnly,
    model.availableErrorScores,
    thresholds,
    isEdit,
    initialThreshold,
  ])

  const options = useMemo(() => getFilteredOptions(), [getFilteredOptions])

  const [threshold, setThreshold] = useState(
    initialThreshold
      ? options.find(t => isThresholdSameAsOption(initialThreshold, t))!
      : options[0],
  )
  const [thresholdValue, setThresholdValue] = useState(
    initialThreshold?.threshold ?? threshold.defaultValue,
  )

  useEffect(() => {
    if (isEdit) return
    setThresholdValue(threshold.defaultValue)
  }, [isEdit, threshold])

  const handleSubmit = (): void => {
    const option = options.find(o => o.value === threshold.value)
    if (!option) return
    if (onSubmit)
      onSubmit({
        errorScore: {
          kind: option.errorScoreKind,
          transform: option.transform,
        },
        thresholdKind: option.thresholdKind,
        threshold: thresholdValue,
      })
  }

  const debouncedThresholdChange = useMemo(
    () => debounce((val: number) => setThresholdValue(val), 500),
    [setThresholdValue],
  )

  const onInputChange = (val?: number): void => {
    if (val === undefined) return
    debouncedThresholdChange(val)
  }

  const onSliderChange = (val: number): void => {
    setThresholdValue(val)
  }
  return (
    <div className="flex w-full flex-col gap-s">
      {!readOnly && (
        <div className="flex items-center gap-s">
          <div className="flex w-[230px] flex-col gap-2xs">
            <Text variant="description" bold>
              Threshold Type
            </Text>
            <SelectInput
              bold
              textVariant="description"
              value={threshold}
              options={options}
              onChange={v => setThreshold(options.find(o => o.value === v)!)}
              className="py-xs"
              leftComponent={
                <div className="h-[14px]">
                  <Tooltip
                    direction="bottom-start"
                    render={() => (
                      <Text variant="description">{threshold.description}</Text>
                    )}
                  >
                    <Icon
                      icon={light('circle-info')}
                      className="translate-y-[-4px]"
                    />
                  </Tooltip>
                </div>
              }
            />
          </div>
          <div className="flex w-[110px] flex-col gap-2xs">
            <Text variant="description" bold>
              Threshold Value
            </Text>
            <NumberInput
              step={0.001}
              value={thresholdValue}
              onChange={onInputChange}
            />
          </div>
          <div className="mt-s flex items-center justify-between gap-s">
            <Button
              variant="primary"
              title={isEdit ? 'Save' : 'Add'}
              className="w-[88px]"
              disabled={
                isEdit &&
                thresholdValue === initialThreshold?.threshold &&
                threshold.errorScoreKind ===
                  initialThreshold?.errorScore.kind &&
                threshold.transform === initialThreshold?.errorScore.transform
              }
              onClick={handleSubmit}
            />
            <Button
              variant="secondary"
              title="Cancel"
              onClick={onCancel}
              disabled={
                isEdit &&
                thresholdValue === initialThreshold?.threshold &&
                threshold.errorScoreKind ===
                  initialThreshold?.errorScore.kind &&
                threshold.transform === initialThreshold?.errorScore.transform
              }
            />
          </div>
          {isEdit && (
            <div className="flex flex-1 justify-end">
              <Button
                className="mt-s"
                variant="primary"
                buttonColor="danger"
                title="Delete"
                onClick={onDelete}
              />
            </div>
          )}
        </div>
      )}
      <div className="flex flex-1">
        <AnomalyThresholdInput
          id={model.id}
          errorScoreKind={threshold.errorScoreKind}
          transform={threshold.transform}
          value={thresholdValue}
          thresholdType={threshold.thresholdKind}
          onChange={onSliderChange}
          readOnly={readOnly}
        />
      </div>
    </div>
  )
}

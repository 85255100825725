import { useTags } from 'tags/api'
import { ChartAxis, ChartTableProps } from 'src/types/chartTypes'
import {
  DynamicTable,
  Text,
  Spinner,
  TableTag,
  Banner,
  Button,
} from 'src/components/ui'
import { getTagsConfig } from './table-configs'

export function TagsTable(props: ChartTableProps): JSX.Element {
  return (
    <div className="h-full">
      <Text variant="title" bold>
        Select Tags to View
      </Text>
      <Table {...props} />
    </div>
  )
}

function formatEngUnit(engUnit: string | null | undefined): string {
  switch (engUnit) {
    case undefined:
    case null:
    case 'None':
      return ''
    default:
      return engUnit
  }
}

function Table({
  chart,
  addSeries,
  removeSeries,
}: ChartTableProps): JSX.Element {
  const tagsQuery = useTags()

  if (tagsQuery.isLoading) {
    return <Spinner />
  }

  if (tagsQuery.isError) {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            onClick={() => tagsQuery.refetch()}
            title="Try again"
          />
        }
      >
        Failed to load tags
      </Banner>
    )
  }

  // map to the data required by the tag table
  const data: TableTag[] = tagsQuery.data.map(tag => ({
    id: tag.tagName,
    description: tag.description ?? '',
    engUnit: formatEngUnit(tag.engUnit),
    displayTagName: tag.displayName ? tag.displayName : tag.tagName,
  }))

  const selected = chart.data.flatMap(({ id, type }) =>
    type === 'tag' ? [id] : [],
  )

  const selectedScatter = chart.data.flatMap(({ id, axis }) =>
    axis === ChartAxis.X || axis === ChartAxis.Y ? [{ id, axis }] : [],
  )

  const config = getTagsConfig({
    chartType: chart.type,
    addSeries,
    removeSeries,
  })

  return (
    <div className="h-[calc(100%-24px)]">
      <DynamicTable
        id="TrendTags"
        data={data}
        config={config}
        selectedRows={selectedScatter.length ? undefined : selected}
        selectedScatter={selectedScatter}
        maxHeightFull
        fuzzySearch
        fuzzySearchField={['displayTagName', 'description', 'engUnit']}
        shouldAutofocusSearch
      />
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AgGridReact } from '@ag-grid-community/react'
import {
  ColDef,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ModuleRegistry,
} from '@ag-grid-community/core'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { ErrorDisplay } from 'pages/app'
import { useTimeAgo } from 'src/utility/time'
import { Button, Icon, Spinner, Text, Tooltip } from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { OpcUaNavigation } from './OpcUaNavigation'
import {
  ErrorNode,
  NodeType,
  ROOT_NODE_ID,
  StatusCode,
} from './opc-ua-connection.types'
import {
  constructRoute,
  FetchType,
  fetchNodeData,
  getNodeIcon,
  getTagHierarchyConfig,
  LoadingErrorRenderer,
  useFetchNodeValuesMutation,
} from './opc-ua-browse'
import { useOpcConnection } from './opc-ua-connection.api'

ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  RowGroupingModule,
  ServerSideRowModelModule,
])

export function OpcUaBrowse(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId)
    throw new Error('Missing siteId or gatewayId or connectionId')

  const gridRef = React.useRef<AgGridReact>(null)
  const failedIdsRef = useRef<ErrorNode[]>([])
  const [lastRefreshed, setLastRefreshed] = useState<Date>()
  const timeAgo = useTimeAgo(lastRefreshed, 30000)
  const {
    data: connection,
    isError,
    isLoading,
    error,
    refetch,
  } = useOpcConnection(siteId, gatewayId, connectionId)
  const fetchNodeValuesMutation = useFetchNodeValuesMutation(connectionId)
  const { setTitleComponent } = useNavigationContext()
  useEffect(() => {
    setTitleComponent(
      <OpcUaNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
        opcConnectionId={connectionId}
        browse
      />,
    )
    return () => setTitleComponent(null)
  }, [connectionId, gatewayId, orgId, setTitleComponent, siteId])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      minWidth: 300,
      initialFlex: 1,
      sortable: false,
      resizable: true,
    }
  }, [])

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      field: 'name',
      headerName: 'Name',
      cellRendererParams: {
        innerRenderer: (params: ICellRendererParams) => {
          const { icon, className } = getNodeIcon(params.data.nodeType)
          return (
            <div className="flex items-center gap-xs">
              <Icon icon={icon} size="regular" className={className} />
              <Tooltip
                direction="right"
                zIndex={zIndex.modalLegendMenu}
                render={() => params.data.id}
              >
                {params.data.name}
              </Tooltip>
            </div>
          )
        },
      },
    }
  }, [])

  const isServerSideGroup = useCallback((dataItem: any) => {
    return dataItem.type === NodeType.Folder
  }, [])

  const getServerSideGroupKey = useCallback((dataItem: any) => {
    return dataItem.id
  }, [])

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const dataSource: IServerSideDatasource = {
        getRows: async (params: IServerSideGetRowsParams) => {
          try {
            const data = await fetchNodeData({
              connectionId,
              fetchType: FetchType.HIERARCHY_AND_VALUES,
              nodeId:
                params.request.groupKeys[params.request.groupKeys.length - 1],
            })

            if (!data.nodeData?.node.nodeId) {
              return params.fail()
            } else {
              const dataWithValues = data.nodeData.children.map(node => {
                const value = data.values?.find(
                  val => val.nodeId === node.nodeId,
                )
                return {
                  ...node,
                  value: value?.value,
                  status: value?.value?.status.severity,
                  statusCode: value?.value?.status.value,
                }
              })
              if (data.nodeData.children.length === 0) {
                params.parentNode.setDataValue('type', NodeType.Tag)
              }
              // If root node
              if (!params.request.groupKeys.length) {
                return params.success({
                  rowData: [
                    {
                      id: data.nodeData.node.nodeId,
                      name:
                        data.nodeData.node.displayName ||
                        data.nodeData.node.browseName ||
                        data.nodeData.node.nodeId,
                      type: 'folder',
                      nodeType: data.nodeData.node.__typename,
                    },
                  ],
                })
              }
              const rowData = dataWithValues.map(node => {
                const dataType =
                  node.__typename === 'VariableOpcNode'
                    ? node.dataType
                    : undefined
                return {
                  id: node.nodeId,
                  name: node.displayName || node.browseName || node.nodeId,
                  type: 'folder',
                  nodeType: node.__typename,
                  dataType,
                  status: node.status
                    ? node.status === 'GOOD'
                      ? StatusCode.Good
                      : StatusCode.Bad
                    : undefined,
                  statusCode: node.statusCode,
                  value: node.value
                    ? Object.values(node.value?.value ?? {})[0]
                    : undefined,

                  updatedAt:
                    node.__typename === 'VariableOpcNode'
                      ? node.value?.sourceTimestamp
                      : undefined,
                }
              })

              return params.success({
                rowData,
              })
            }
          } catch (error: any) {
            if (params.request.groupKeys.length) {
              failedIdsRef.current.push({
                id: params.request.groupKeys[
                  params.request.groupKeys.length - 1
                ],
                reason: error.message,
              })
            } else {
              failedIdsRef.current.push({
                id: ROOT_NODE_ID,
                reason: error.message,
              })
            }
            return params.fail()
          }
        },
      }
      setTimeout(() => {
        params.api.setGridOption('serverSideDatasource', dataSource)
      }, 0)
    },
    [connectionId],
  )

  const refreshCache = useCallback(
    async (node: IRowNode, isRoot?: boolean) => {
      if (isRoot) {
        gridRef.current?.api.refreshServerSide({ purge: true })
        failedIdsRef.current = []
        return
      }
      const route = constructRoute(node)
      const refreshingNodeId = route[route.length - 1]
      if (
        failedIdsRef.current.find(errNode => errNode.id === refreshingNodeId)
      ) {
        failedIdsRef.current = failedIdsRef.current.filter(
          errNode => errNode.id !== refreshingNodeId,
        )
      }
      // Expand the node if it is collapsed
      if (node && !node.expanded) {
        node.setExpanded(true)
      }

      // Fetch the values for the node
      if (node.data.nodeType === 'VariableOpcNode') {
        const res = await fetchNodeValuesMutation.mutateAsync([node.data.id])
        const value = res?.[0].value
          ? Object.values(res[0].value.value ?? {})[0]
          : undefined
        const updatedAt = res?.[0].value?.sourceTimestamp

        if (value) {
          node.setData({
            ...node.data,
            value,
            updatedAt,
          })
        }
      }
      gridRef.current?.api.refreshServerSide({ route, purge: true })
    },
    [fetchNodeValuesMutation],
  )

  const refreshAllValues = async (): Promise<void> => {
    const nodeIds: string[] = []
    // Get all of the nodeIds for variable opc nodes
    gridRef.current?.api.forEachNode(node => {
      if (node.data.nodeType === 'VariableOpcNode') {
        nodeIds.push(node.data.id)
      }
    })

    const values = await fetchNodeValuesMutation.mutateAsync(nodeIds)

    if (values?.length) {
      values.forEach(value => {
        const node = gridRef.current?.api.getRowNode(value.nodeId)
        node?.setData({
          ...node.data,
          updatedAt: value.value?.sourceTimestamp,
          value: value ? Object.values(value.value?.value ?? {})[0] : undefined,
        })
      })
    }
    setLastRefreshed(new Date())
  }

  if (isError) {
    return (
      <ErrorDisplay
        error={error}
        message="Something went wrong"
        action={refetch}
      />
    )
  }

  if (isLoading) return <Spinner />

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-unnecessary-arbitrary-value
      className="ag-theme-alpine size-full max-w-[100%] p-s"
      id="HierarchyBrowse"
    >
      <div className="flex size-full flex-col gap-s rounded-2xs bg-background p-s">
        <div className="flex justify-between">
          <div className="flex flex-col gap-2xs">
            <Text variant="description">OPC-UA Connection</Text>
            <Text bold>
              {connection?.name ?? connection?.id ?? 'Unknown connection'}
            </Text>
          </div>
          <div className="flex flex-col items-end gap-2xs">
            <Button
              disabled={fetchNodeValuesMutation.isLoading}
              variant="secondary"
              title="Refresh Values"
              onClick={refreshAllValues}
            />
            {lastRefreshed && (
              <Text variant="description">
                Values were refreshed: {timeAgo}
              </Text>
            )}
          </div>
        </div>
        <AgGridReact
          ref={gridRef}
          enableCellTextSelection
          columnDefs={getTagHierarchyConfig(refreshCache)}
          getRowId={data => data.data.id}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          rowModelType={'serverSide'}
          treeData={true}
          isServerSideGroupOpenByDefault={() => false}
          isServerSideGroup={isServerSideGroup}
          getServerSideGroupKey={getServerSideGroupKey}
          onGridReady={onGridReady}
          loadingCellRendererParams={{ refreshCache }}
          loadingCellRenderer={({
            node,
            refreshCache,
          }: {
            node: IRowNode
            refreshCache: (node: IRowNode) => void
          }) => {
            const shouldShowError =
              !!node?.parent?.key &&
              failedIdsRef.current.find(
                errNode => errNode.id === node.parent?.data.id,
              )

            const rootNodeError = failedIdsRef.current.find(
              errNode => errNode.id === ROOT_NODE_ID,
            )
            if (shouldShowError || rootNodeError)
              return (
                <LoadingErrorRenderer
                  node={node}
                  refreshCache={refreshCache}
                  failedNodes={failedIdsRef.current}
                  isRoot={!!rootNodeError}
                />
              )
            const route = constructRoute(node)
            return (
              <div
                className="flex items-center gap-xs"
                style={{ marginLeft: (route.length - 1) * 28 + 17 }}
              >
                {/* eslint-disable-next-line tailwindcss/no-custom-classname  */}
                <span className="ag-icon ag-icon-loading" />
                <span>Loading...</span>
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

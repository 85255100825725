import React from 'react'
import { Link, NavLink as RouterNavLink } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Icon } from 'src/components/ui'
import { disableForWorkshop } from 'src/utility/metropolis'
import { triggerChartRedraw } from 'src/utility'
import { useAuth } from 'src/contexts/auth'
import { IntelecyIcon } from './IntelecyIcon'
import { Logo } from './Logo'

const checkForAccessByTitle = (title: string, hasAccess: boolean): boolean =>
  title === 'Labs' ? hasAccess : true

interface NavLinkProps {
  to: any
  external?: boolean
  exact?: boolean
  title?: string
  disable?: boolean
  children?: React.ReactNode
  collapsed?: boolean
}

const NavLink = ({
  to,
  external,
  children,
  exact,
  title,
  disable,
  collapsed,
}: NavLinkProps): JSX.Element => {
  return external ? (
    <Link
      to={to}
      title={title}
      className={`menu-text relative flex items-center gap-s text-[0.9rem] text-[#5c5c5c] no-underline transition-colors hover:bg-background-brand-hover/50 ${
        disable ? 'pointer-events-none opacity-50' : ''
      } ${collapsed ? 'p-[1em]' : 'py-[0.75em] pl-[2em] pr-[2.8em]'} `}
    >
      {children}
    </Link>
  ) : (
    <RouterNavLink
      to={to === '' ? '/' : to}
      title={title}
      end={exact}
      className={({ isActive }) =>
        `flex items-center gap-s no-underline text-[0.9rem] relative transition-colors ${
          disable ? 'pointer-events-none opacity-50' : ''
        } ${collapsed ? 'p-[1em]' : 'py-[0.75em] pl-[2em] pr-[2.8em]'} ${
          isActive
            ? 'menu-text-active text-text-secondary bg-background-brand/30 before:content-[""] before:absolute before:inset-y-0 before:left-0 before:w-[0.5em] before:bg-background-brand'
            : 'text-[#5c5c5c] hover:bg-background-brand-hover/50 hover:text-text-secondary menu-text'
        }`
      }
    >
      {children}
    </RouterNavLink>
  )
}

interface MenuItem {
  icon: JSX.Element
  title: string
  link: string
  external?: boolean
  disable?: boolean
  exact?: boolean
}

const faMenuIcon = (icon: IconProp): React.ReactElement => {
  return <Icon className="max-h-icon-big w-icon-big" size="big" icon={icon} />
}

const mainItems: readonly MenuItem[] = [
  {
    icon: faMenuIcon(light('table-columns')),
    title: 'Dashboard',
    link: '',
    exact: true,
  },
  {
    icon: faMenuIcon(light('chart-line-up')),
    title: 'Trend',
    link: '/trend',
  },
  {
    icon: faMenuIcon(light('magnifying-glass-chart')),
    title: 'Data Explorer',
    link: '/data-explorer',
  },
  {
    icon: faMenuIcon(light('brain')),
    title: 'Models',
    link: '/models',
  },
  {
    icon: faMenuIcon(light('eye')),
    title: 'Forecast',
    link: '/forecast',
  },
  {
    icon: faMenuIcon(light('triangle-exclamation')),
    title: 'Anomalies',
    link: '/anomalies',
  },
  {
    icon: faMenuIcon(light('sitemap')),
    title: 'Assets',
    link: '/assets',
  },
  {
    icon: faMenuIcon(light('flask')),
    title: 'Labs',
    link: '/labs',
  },
  {
    icon: faMenuIcon(light('file-lines')),
    title: 'Docs',
    link: '/docs',
    external: true,
  },
]

type Props = {
  siteId?: string
  collapsed?: boolean
  setCollapsed: (collapsed: boolean) => void
}

export default function Sidebar({
  siteId,
  collapsed,
  setCollapsed,
}: Props): JSX.Element {
  const {
    viewer: { hasAppAccess },
  } = useAuth()

  React.useEffect(() => {
    triggerChartRedraw()
  }, [collapsed])

  let menuItems = [...mainItems]
  menuItems = menuItems.filter(item =>
    checkForAccessByTitle(item.title, hasAppAccess),
  )

  if (siteId) {
    menuItems = menuItems.map(item => ({
      ...item,
      link: item.external ? item.link : `/site/${siteId}${item.link}`,
      disable: disableForWorkshop(item.title, siteId),
    }))
  }

  return (
    <div
      className={`items-stretch bg-background ${
        collapsed ? '' : 'w-[240px] min-w-[240px]'
      } relative`}
    >
      <div className="sticky left-0 top-0 flex h-screen flex-col overflow-y-auto">
        <Link to="/" className="mx-auto mb-0 mt-[1em]">
          {collapsed ? <IntelecyIcon size="2em" /> : <Logo />}
        </Link>
        <nav className="mt-[1em]">
          {menuItems.map((menuItem, i) => {
            const { icon, title, link, external, disable, exact } = menuItem

            return (
              <div key={i}>
                <NavLink
                  collapsed={collapsed}
                  exact={exact}
                  to={link}
                  external={external}
                  title={collapsed ? title : undefined}
                  disable={disable}
                >
                  {icon}
                  {!collapsed && <span>{title}</span>}
                </NavLink>
              </div>
            )
          })}
        </nav>
        <div className="flex flex-1 flex-col justify-end">
          {!collapsed && (
            <div
              className="p-[1em] text-[0.7em] text-[#999999]"
              title={import.meta.env.VITE_VERSION ?? 'unknown'}
            >
              App Build:{' '}
              <span>{import.meta.env.VITE_BUILD_NUMBER ?? '00000000.0'}</span>
            </div>
          )}

          <button
            className="flex h-[60px] justify-end border-0 border-t border-solid border-border bg-transparent p-[1em] outline-0 transition-all hover:bg-black/10"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <Icon icon={light('angle-double-right')} title="Expand" />
            ) : (
              <Icon icon={light('angle-double-left')} title="Collapse" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

import { GqlAnomalyState } from 'src/services'

export function mapReadableAnomalyState(state: GqlAnomalyState): string {
  switch (state) {
    case GqlAnomalyState.InProgress:
      return 'In Progress'
    case GqlAnomalyState.Anomaly:
      return 'Anomaly'
    case GqlAnomalyState.AnomalyKnown:
      return 'Anomaly Known'
    case GqlAnomalyState.AnomalyUnknown:
      return 'Anomaly Unknown'
    case GqlAnomalyState.Inconclusive:
      return 'Inconclusive'
    case GqlAnomalyState.New:
      return 'New'
    case GqlAnomalyState.NormalBehaviour:
      return 'Normal Behaviour'
    case GqlAnomalyState.NotAnomaly:
      return 'Not Anomaly'
    case GqlAnomalyState.Uncertain:
      return 'Uncertain'
    default:
      return 'Unknown'
  }
}

import { format } from 'date-fns'
import { Text, Button } from 'src/components/ui'
import { PagePadding } from 'src/utility/constants/StyleConstants'
import ErrorLogo from './images/ErrorLogo.svg'

interface ErrorPageProps {
  message: string
  info: [string, string][]
  action?: () => void
  actionTitle?: string
}

export function ErrorPage({
  message,
  info,
  action = window.location.reload,
  actionTitle = 'Refresh',
}: ErrorPageProps): JSX.Element {
  return (
    <div
      className="m-s mt-0 flex flex-col items-center rounded-2xs bg-background"
      style={{
        height: `calc(100% - ${PagePadding.y}px)`,
      }}
    >
      <img className="mt-m" src={ErrorLogo}></img>
      <Text variant="title" bold className="mt-m !text-2xl">
        Oops!
      </Text>
      <Text className="mt-s">It looks like something went wrong!</Text>
      <Button
        variant="primary"
        title={actionTitle}
        className="mt-m"
        onClick={() => action()}
      />
      <div className="mx-auto mt-m w-full max-w-[512px]">
        <Text bold>Error message</Text>
        <Text className="mt-xs" variant="description">
          {message}
        </Text>
      </div>
      <div className="mx-auto mt-s flex w-full max-w-[512px] flex-col gap-s rounded-2xs bg-background-disabled-light px-m py-s">
        <Text className="text-center" variant="small">
          Timestamp:{' '}
          <span className="font-500">
            {format(Date.now(), 'dd MMMM yyyy, hh:mm a')}
          </span>
        </Text>
        {info.map(([key, value], index) => (
          <div key={index}>
            <Text bold variant="description">
              {key}
            </Text>
            <Text variant="code" className="break-words">
              {value}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}

import { useEffect, useMemo } from 'react'
import { useForecastModels } from 'models/api'
import { isRunning } from 'models/model/model.utils'
import { triggerChartRedraw, useUrlQuery, getArray } from 'src/utility'
import { DynamicTable, Text, Spinner, Banner, Button } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { getForecastModelsConfig } from './forecastsTable.config'

export function ForecastsTab(): JSX.Element {
  const forecastModelsQuery = useForecastModels()
  const { id: factory } = useSite()
  const models = (forecastModelsQuery.data || [])
    .filter(({ state }: any) => isRunning(state))
    .map(m => {
      return {
        ...m,
        tag: {
          ...m.tag,
          displayTagName: m.tag.displayName
            ? m.tag.displayName
            : m.tag.tagName || '',
        },
      }
    })

  const { query, update } = useUrlQuery()
  const activeModels = useMemo(() => getArray(query.models), [query.models])

  const addModel = (modelId: string): void =>
    update({
      models: [...activeModels, modelId],
    })

  const removeModel = (modelId: string): void =>
    update({
      models: activeModels.filter(id => id !== modelId),
    })

  useEffect(() => {
    triggerChartRedraw()
  }, [activeModels])

  const toggleRow = ({ id }: any): any => {
    if (activeModels.find(el => el === id)) {
      removeModel(id)
    } else {
      addModel(id)
    }
  }

  const actions = {
    toggleRow,
  }

  if (forecastModelsQuery.isError) {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Try again"
            onClick={() => forecastModelsQuery.refetch()}
          />
        }
      >
        Failed to load forecast models
      </Banner>
    )
  }

  return (
    <div className="flex h-full flex-col gap-[0.5em] pb-xs">
      <Text>Select Forecasts to Show</Text>
      {forecastModelsQuery.isLoading ? (
        <Spinner />
      ) : (
        <DynamicTable
          id={`${factory}-ForecastModels`}
          data={models}
          config={getForecastModelsConfig(actions)}
          selectedRows={activeModels}
        />
      )}
    </div>
  )
}

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useMemo } from 'react'
import { GqlOpcUaNode, GqlOpcUaNodeClass } from 'src/services'

export function getNodeIcon(
  nodeClass: GqlOpcUaNodeClass,
  type?: string,
): IconProp {
  if (nodeClass === GqlOpcUaNodeClass.Object && type === 'FolderType') {
    return light('folder')
  }
  if (nodeClass === GqlOpcUaNodeClass.Variable) {
    return light('sensor')
  }

  return light('cube')
}

/**
 * Hook to create paths for nodes in a node map.
 * @param nodeMap - The original map of nodes.
 * @returns A new map with paths added to each node.
 */
export function useNodePaths(
  nodeMap: Map<string, GqlOpcUaNode>,
  rootNodeId: string,
): Map<string, GqlOpcUaNode & { path?: string[] }> {
  return useMemo(() => {
    const processed = new Set<string>()
    const newNodeMap = new Map(
      Array.from(nodeMap.entries()).map(([key, value]) => [
        key,
        { ...value, path: [] as string[] },
      ]),
    )

    function addPaths(nodeId: string, path: string[] = []): void {
      if (processed.has(nodeId)) return
      processed.add(nodeId)

      const node = newNodeMap.get(nodeId)
      if (!node) return

      node.path = [...path, nodeId]

      for (const childNode of node.forwardReferences) {
        addPaths(childNode.targetId, node.path)
      }
    }

    addPaths(rootNodeId)
    return newNodeMap
  }, [nodeMap, rootNodeId])
}

import { useCallback } from 'react'
import { useSessionValue } from './useSessionValue'
import { idSerde } from './serde'

export function useInvalidateSessionViewId(
  currentFactory: string,
): (id: string) => void {
  const sessionTrendViewId = useSessionValue(`${currentFactory}-id`, idSerde)

  return useCallback(
    (id: string) => {
      if (sessionTrendViewId.get() === id) {
        sessionTrendViewId.remove()
      }
    },
    [sessionTrendViewId],
  )
}

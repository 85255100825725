import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useModal } from 'react-modal-hook'
import { useNavigate } from 'react-router-dom'
import { Site, SiteRole } from 'src/types'
import { Button, Icon, Text, Tooltip } from 'src/components/ui'
import { AddSiteUserModal } from '../../users'

type Props = {
  site: Site
}

export function SiteCard({ site }: Props): JSX.Element {
  const navigate = useNavigate()

  const [showModal, closeModal] = useModal(() => (
    <AddSiteUserModal site={site} onClose={closeModal} />
  ))

  const gatewaysDisabled =
    !site.viewerRole || site.viewerRole !== SiteRole.ADMIN
  return (
    <>
      <div
        className="flex w-[570px] rounded-2xs border border-solid border-border p-m pr-s"
        key={site.id}
      >
        <Icon
          icon={light('industry-windows')}
          className="mr-s !h-[30px] !w-[36px] text-icon-secondary"
        />
        <div className="flex w-full">
          <div className="flex w-full items-start justify-between">
            <div className="max-w-[50%]">
              <Text variant="description">Site</Text>
              <Text variant="content" bold className="mb-xs mt-2xs">
                {site.orgName} | {site.name}
              </Text>
              <Text variant="small" className="mt-s whitespace-nowrap">
                Users Assigned
              </Text>
              <Text variant="description" bold className="mb-xs mt-2xs">
                {site.numOfUsers}
              </Text>
            </div>
            <div>
              <Text variant="description" className="whitespace-nowrap">
                Your Role
              </Text>
              <Text variant="content" bold className="mb-xs mt-2xs">
                {site.viewerRole ?? 'None'}
              </Text>
              <Text variant="small" className="mt-s">
                Gateways
              </Text>
              <Tooltip
                isOpen={!gatewaysDisabled ? false : undefined}
                direction="bottom-start"
                render={() => {
                  return <Text>You don't have the necessary role to view</Text>
                }}
              >
                <Text
                  variant="description"
                  bold
                  className={classNames(
                    gatewaysDisabled && 'text-text-tertiary',
                  )}
                >
                  {gatewaysDisabled ? 'N/A' : site.numOfGateways}
                </Text>
              </Tooltip>
            </div>
            <div>
              {site.viewerRole && (
                <Button
                  variant={'primary'}
                  title="View Details"
                  className="mb-xs"
                  onClick={() =>
                    navigate(`/settings/orgs/${site.orgId}/sites/${site.id}`)
                  }
                />
              )}

              {site.viewerRole === SiteRole.ADMIN && (
                <Button
                  title="Add User"
                  variant="icon-primary"
                  icon={regular('circle-plus')}
                  onClick={showModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

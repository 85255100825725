import { useModal } from 'react-modal-hook'
import { sort } from 'semver'
import { Banner, Button, Spinner, Text } from 'src/components/ui'
import { GqlGatewayFragment } from 'src/services'
import {
  GatewayReleasesModal,
  GatewayVersionRenderer,
} from 'gateway/components'
import { useGatewayReleases } from 'gateway/api'
import { GatewayIcon } from './GatewayIcon'

type Props = {
  gateway: GqlGatewayFragment
}
export function GatewayOverview({ gateway }: Props): JSX.Element {
  const gatewayReleasesQuery = useGatewayReleases()

  const [showModal, closeModal] = useModal(() => (
    <GatewayReleasesModal onClose={closeModal} />
  ))

  if (gatewayReleasesQuery.isLoading) {
    return <Spinner />
  }

  if (gatewayReleasesQuery.isError) {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Retry"
            onClick={() => gatewayReleasesQuery.refetch}
          />
        }
      >
        Filed to fetch gateway releases
      </Banner>
    )
  }

  const latestVersion = sort(
    gatewayReleasesQuery.data.filter(r => !r.isBeta).map(r => r.semver),
  ).pop()

  return (
    <div className="flex w-full justify-between">
      <div className="flex gap-s px-s">
        <GatewayIcon />
        <div className="flex gap-3xl">
          <div className="flex flex-col gap-s">
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Gateway</Text>
              <Text bold>{gateway.hostname}</Text>
            </div>
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Hostname</Text>
              <Text bold>{gateway.hostname} </Text>
            </div>
          </div>
          <div className="flex flex-col gap-s">
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Gateway ID</Text>
              <Text bold>{gateway.id}</Text>
            </div>
            <div className="flex flex-col gap-2xs">
              <Text variant="description">Version</Text>
              <GatewayVersionRenderer
                bold
                version={gateway.version ?? null}
                latestVersion={latestVersion}
                onUpgradeClick={showModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  getRangeIcon,
  getTimeRangeLabel,
} from 'pages/site/DataExplorerNew/data-explorer.utils'
import { Button, Icon, Text } from 'src/components/ui'
import { TimeRangeDef } from 'src/dex/time_range'

type Props = {
  timeRange: TimeRangeDef
}

export function TimeRangeDisplay({ timeRange }: Props): JSX.Element {
  return (
    <div className="flex flex-col gap-s">
      <div className="flex items-center gap-xs">
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('chevron-left')}
          iconSize="small"
        />
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('magnifying-glass-plus')}
          iconSize="small"
        />
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('magnifying-glass-minus')}
          iconSize="small"
        />
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('chevron-right')}
          iconSize="small"
        />
      </div>
      <div className="flex items-start gap-xs">
        <Button
          variant="icon"
          className="!px-xs !py-2xs"
          icon={light('pencil')}
          iconSize="small"
        />
        <div className="flex flex-col gap-2xs">
          <Text>
            Time range:{' '}
            <span className="font-500">
              <Icon icon={getRangeIcon(timeRange?.label ?? '')} />{' '}
              <span>{timeRange?.label ?? 'Unnamed time range'}</span>
            </span>
          </Text>
          <Text variant="description" bold>
            {getTimeRangeLabel(timeRange)}{' '}
          </Text>
        </div>
      </div>
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getSegmentationIcon } from 'data-explorer/data-explorer.utils'
import { Button, Icon, Text } from 'src/components/ui'
import { SegmentationDef } from 'src/dex/segmentation'
import { SavedTimeSeries } from 'src/dex/time_series'
import { SegmentationConditionDisplay } from './components'

type Props = {
  segmentation: SegmentationDef
  timeSeries: SavedTimeSeries[]
}

export function SegmentationDisplay({
  segmentation,
  timeSeries,
}: Props): JSX.Element {
  return (
    <div className="flex items-start gap-xs">
      <Button
        variant="icon"
        className="!px-xs !py-2xs"
        icon={light('pencil')}
        iconSize="small"
      />
      <div className="flex flex-col gap-2xs">
        <Text>
          Segmentation:{' '}
          <span className="font-500">
            <Icon icon={getSegmentationIcon(segmentation.kind?.$case ?? '')} />{' '}
            <span>{segmentation.label ?? 'Unknown segmentation'}</span>
          </span>
        </Text>
        <SegmentationConditionDisplay
          segmentation={segmentation}
          timeSeries={timeSeries}
        />
      </div>
    </div>
  )
}

import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import { minutesToMilliseconds } from 'date-fns'
import {
  GqlCreateOpcConnectionInput,
  GqlOpcConnectionFragment,
  GqlTestOpcUaConnectionQuery,
  GqlTestOpcUaConnectionQueryVariables,
  GqlValidateOpcConnectionInput,
  GqlValidateOpcUaConnectionQuery,
} from 'src/services'
import { graphqlApi } from 'src/services/graphQL'
import * as api from 'src/services'
import { GATEWAYS_QUERY } from '../gateway'

export function useTestOpcConnectionMutation(
  factory: string,
  agent: string,
): UseMutationResult<GqlTestOpcUaConnectionQuery, unknown, string> {
  return useMutation({
    mutationFn: (endpoint: string) =>
      testOpcUaConnection({
        factory,
        agent,
        url: endpoint,
      }),
  })
}

export async function testOpcUaConnection(
  input: GqlTestOpcUaConnectionQueryVariables,
): Promise<GqlTestOpcUaConnectionQuery> {
  const api = await graphqlApi()
  const data = await api.TestOpcUaConnection(input)
  return data
}

export function useValidateOpcConnectionMutation(
  factory: string,
  agentId: string,
): UseMutationResult<
  GqlValidateOpcUaConnectionQuery,
  unknown,
  GqlValidateOpcConnectionInput
> {
  return useMutation({
    mutationFn: (connection: GqlValidateOpcConnectionInput) =>
      validateOpcUaConnection({
        factory,
        agentId,
        connection,
      }),
  })
}

export async function validateOpcUaConnection(
  input: api.GqlValidateOpcUaConnectionQueryVariables,
): Promise<api.GqlValidateOpcUaConnectionQuery> {
  const api = await graphqlApi()
  const data = await api.ValidateOpcUaConnection(input)
  return data
}

export function useCreateOpcUaConnection(
  siteId: string,
): UseMutationResult<
  GqlOpcConnectionFragment | undefined,
  unknown,
  GqlCreateOpcConnectionInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (input: GqlCreateOpcConnectionInput) => {
      return api.createOpcUaConnection(input)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GATEWAYS_QUERY, siteId])
    },
  })
}

export function useUpdateOpcUaConnection(
  siteId: string,
): UseMutationResult<
  GqlOpcConnectionFragment | undefined,
  unknown,
  api.GqlUpdateOpcConnectionInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (input: api.GqlUpdateOpcConnectionInput) => {
      return api.updateOpcUaConnection(input)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GATEWAYS_QUERY, siteId])
    },
  })
}

export function useOpcConnections<T = GqlOpcConnectionFragment[]>(
  factoryId: string,
  agentId: string,
  select?: (data: api.GqlOpcConnectionFragment[]) => T,
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey: ['opcConnections', factoryId, agentId],
    queryFn: async () => {
      return api.fetchAgentOpcConnections(factoryId, agentId)
    },
    select,
  })
}

export function useOpcConnection(
  factoryId: string,
  agentId: string,
  connectionId: string,
): UseQueryResult<GqlOpcConnectionFragment, Error> {
  return useOpcConnections(factoryId, agentId, connections => {
    const connection = connections.find(c => c.id === connectionId)
    if (!connection) {
      throw new Error(`Connection with id ${connectionId} not found`)
    }
    return connection
  })
}

export function useAgentSubscriptions<
  T = api.GqlOpcStreamFragment[] | undefined,
>(
  agentId: string,
  select?: (data: api.GqlOpcStreamFragment[] | undefined) => T,
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey: ['opcSubscriptions', agentId],
    queryFn: async () => {
      return api.fetchOpcStreams(agentId)
    },
    select,
  })
}

export function useOpcSubscription(
  subscriptionId: string,
): UseQueryResult<api.GqlOpcStreamFragment> {
  return useQuery({
    queryKey: ['opcSubscription', subscriptionId],
    queryFn: async () => {
      return api.fetchOpcStream(subscriptionId)
    },
  })
}

export function useOpcConnectionSubscriptions(
  agentId: string,
  connectionId: string,
): UseQueryResult<api.GqlOpcStreamFragment[], Error> {
  return useAgentSubscriptions(
    agentId,
    data => data?.filter(s => s.opcConnectionId === connectionId) ?? [],
  )
}

export function useOpcSubscriptionNodes(
  subscriptionId: string,
): UseInfiniteQueryResult<api.GqlOpcStreamNodeConnection> {
  return useInfiniteQuery({
    queryKey: ['opcSubscriptionNodes', subscriptionId],
    queryFn: async ({ pageParam = null }) => {
      return api.fetchOpcStreamNodes(subscriptionId, 5000, pageParam)
    },
    getNextPageParam: lastPage => {
      if (lastPage.pageInfo.hasNextPage === false) return undefined
      return lastPage.pageInfo.endCursor
    },
    // We don't want to refetch data on window focus and trigger a bunch of requests
    // Subscriptions are not expected to change often
    refetchOnWindowFocus: false,
    staleTime: minutesToMilliseconds(5),
  })
}

export function useOpcHierarchyNodes(
  connectionId: string,
): UseInfiniteQueryResult<api.GqlOpcUaNodeConnection> {
  return useInfiniteQuery({
    queryKey: ['opcHierarchyNodes', connectionId],
    queryFn: async ({ pageParam = null }) => {
      return api.fetchHierarchyNodes(connectionId, 5000, pageParam)
    },
    getNextPageParam: lastPage => {
      if (lastPage.pageInfo.hasNextPage === false) return undefined
      return lastPage.pageInfo.endCursor
    },
    // We don't want to refetch data on window focus and trigger a bunch of requests
    // Hierarchy is not expected to change often
    refetchOnWindowFocus: false,
    staleTime: minutesToMilliseconds(5),
  })
}

export function useOpcSyncTasks(
  connectionId: string,
  enabled = true,
): UseQueryResult<api.OpcUaSyncTasks> {
  return useQuery({
    queryKey: ['opcSyncTasks', connectionId],
    queryFn: async () => {
      return api.fetchOpcUaSyncTasks(connectionId)
    },
    enabled,
  })
}

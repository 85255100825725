import { useRef, useMemo } from 'react'
import Highcharts from 'highcharts/highstock'
import more from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { getGraphColor } from 'src/utility/graphUtils/graphColors'
import useTimeRange from 'src/contexts/timeRange'
import { useModel, useModelForecast } from 'models/api'
import {
  Banner,
  Button,
  Icon,
  SelectInput,
  Spinner,
  Text,
  useCursor,
  useHeightListener,
  useLoading,
  useZoom,
} from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { forecastChartConfig } from './forecastChartConfig'

// FIXME: is this even needed?
more(Highcharts)

interface ChartProps {
  modelId: string
}

function Chart({ modelId }: ChartProps): JSX.Element {
  const [{ prediction, tagData }, isPending] = useModelForecast(modelId)
  const ref = useRef<HighchartsReact.RefObject>(null)
  const { height, wrapperRef } = useHeightListener()
  useCursor({ ref, height, withTrackballs: true })
  const { timeRange } = useTimeRange()
  useZoom({ ref, ...timeRange })
  useLoading({
    ref,
    loading: isPending,
    data: [{ data: prediction }, { data: tagData }],
  })

  const chart = useMemo(
    () => (
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={forecastChartConfig({
          tagData,
          height,
          getGraphColor,
          modelId,
          prediction,
        })}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref, height, tagData, prediction],
  )

  return (
    <div
      className="min-h-0 min-w-0"
      style={{ transform: 'translateZ(0)' }}
      ref={wrapperRef}
    >
      {chart}
    </div>
  )
}

interface ForecastChartProp {
  modelId: string
  removeChart: () => void
}

export function ForecastChart({
  modelId,
  removeChart,
}: ForecastChartProp): JSX.Element {
  const modelQuery = useModel(modelId)
  const { rootLink } = useSite()

  if (modelQuery.isError || (!modelQuery.isLoading && !modelQuery.data)) {
    return (
      <div className="h-full rounded-2xs border border-solid border-border p-xs">
        <Banner
          variant="error"
          className="self-start"
          rightComponent={
            <div className="flex items-center gap-2xs">
              <Button
                variant="secondary"
                title="Remove chart"
                onClick={removeChart}
              />
              <Button
                disabled={modelQuery.isRefetching}
                variant="primary"
                title="Try again"
                onClick={() => modelQuery.refetch()}
              />
            </div>
          }
        >
          Unable to load the model
        </Banner>
      </div>
    )
  }

  const displayName =
    modelQuery.data &&
    (modelQuery.data.tag.displayName || modelQuery.data.tag.tagName)

  return (
    <div
      className="grid grid-rows-[auto_1fr] overflow-hidden rounded-2xs border border-solid border-border bg-background p-xs"
      key={modelId}
    >
      <div className="ml-[40px] grid min-h-0 min-w-0 grid-cols-[1fr,auto] gap-[0.5em]">
        {modelQuery.isLoading ? (
          <Spinner small inline />
        ) : (
          <>
            <Text>
              Name: {modelQuery.data.name}, Output tag:{' '}
              <span title={modelQuery.data.tag.tagName}>{displayName}</span>,
              Unit: {modelQuery.data.tag.engUnit}
            </Text>
            <SelectInput
              triggerComponent={<Icon icon={solid('cog')} />}
              options={[
                {
                  label: 'Go to Model',
                  value: ``,
                  to: `${rootLink}/models/${modelId}`,
                },
                { label: 'Remove Chart', value: 'remove' },
              ]}
              onChange={value => {
                if (value === 'remove') {
                  removeChart()
                }
              }}
            />
          </>
        )}
      </div>
      <Chart modelId={modelId} />
    </div>
  )
}

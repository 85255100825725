import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import humanizeDuration from 'humanize-duration'
import { Icon, Text, Tooltip } from 'src/components/ui'
import { OpcUaSyncTasks } from 'src/services'
import { humanDate, humanTime } from 'src/utility/time'
import { OpcUaHierarchySyncLayout } from './OpcUaHierarchySyncLayout'

type Props = {
  tasks: OpcUaSyncTasks
}

export function InProgressSyncContent({ tasks }: Props): JSX.Element {
  // Extract duration from task
  const duration = tasks.lastSuccessfulHierarchySyncTask?.endTime
    ? humanizeDuration(
        tasks.lastSuccessfulHierarchySyncTask.endTime.valueOf() -
          tasks.lastSuccessfulHierarchySyncTask.startTime.valueOf(),
        { largest: 2, round: true },
      )
    : undefined

  return (
    <OpcUaHierarchySyncLayout
      header={{
        icon: regular('sync'),
        text: 'Hierarchy Synchronizing',
      }}
      content={
        <div className="flex flex-col gap-m pl-[20px]">
          <Text>
            Synchronization started{' '}
            <span className="font-500">
              <span>{humanDate(tasks.lastHierarchySyncTask?.startTime)},</span>
            </span>{' '}
            <span>{humanTime(tasks.lastHierarchySyncTask?.startTime)}</span>
          </Text>
          <div className="flex flex-col gap-3xs">
            <Text bold className="text-text-tertiary">
              Current Synchronization
            </Text>
            <div className="flex items-center gap-l">
              <Text>
                <span className="font-500">Nodes found:</span>{' '}
                <span>{tasks.lastHierarchySyncTask?.nodesSeen ?? 0}</span>
              </Text>
              <div className="flex items-center gap-2xs">
                <Text>
                  <span className="font-500">Errors encountered:</span>{' '}
                  <span>{tasks.lastHierarchySyncTask?.browseErrors ?? 0}</span>
                </Text>
                <Tooltip
                  direction="bottom"
                  containerClassName="flex items-center"
                  render={() => (
                    <Text>
                      Errors encountered while trying to browse OPC-UA nodes.
                    </Text>
                  )}
                >
                  <Icon
                    icon={light('circle-question')}
                    className="text-icon-tertiary"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3xs">
            <Text bold className="text-text-tertiary">
              Previous Synchronization
            </Text>
            <div className="flex items-center gap-l">
              <Text>
                <span className="font-500">Nodes found:</span>{' '}
                <span>
                  {tasks.lastSuccessfulHierarchySyncTask?.nodesSeen ?? 0}
                </span>
              </Text>
              <Text>
                <span className="font-500">Errors encountered:</span>{' '}
                <span>
                  {tasks.lastSuccessfulHierarchySyncTask?.browseErrors ?? 0}
                </span>
              </Text>
            </div>
            {duration && (
              <Text>
                <span className="font-500">Time spent:</span> {duration}
              </Text>
            )}
          </div>
        </div>
      }
    />
  )
}

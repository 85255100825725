import { Link } from 'react-router-dom'
import { exactDuration, humanDateTime, humanDuration } from 'src/utility/time'
import { useSite } from 'src/contexts/site'
import { Text, Button, Spinner, Card } from 'src/components/ui'
import { Anomaly, NavigationAnomaly } from 'src/services'

interface NavigationProps {
  anomaly: Anomaly
  className?: string
}

export function Navigation({
  anomaly,
  className,
}: NavigationProps): JSX.Element {
  const { rootLink } = useSite()

  return (
    <Card className={className}>
      {!anomaly ? (
        <Spinner />
      ) : (
        <div className="grid auto-cols-[1fr] grid-flow-col gap-[1em]">
          {anomaly.previous && (
            <div>
              <Text variant="content-rubik" bold className="mb-xs">
                Previous Anomaly
              </Text>
              <AnomalyOverview anomaly={anomaly.previous} />
              <Button
                title="Go to Previous Anomaly"
                as={Link}
                to={`${rootLink}/anomalies/${anomaly.previous.id}`}
                variant="primary"
              />
            </div>
          )}
          {anomaly.next && (
            <div>
              <Text variant="content-rubik" bold className="mb-xs">
                Next Anomaly
              </Text>
              <AnomalyOverview anomaly={anomaly.next} />
              <Button
                title="Go to Next Anomaly"
                as={Link}
                to={`${rootLink}/anomalies/${anomaly.next.id}`}
                variant="primary"
              />
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

function AnomalyOverview({
  anomaly,
}: {
  anomaly: NavigationAnomaly
}): JSX.Element {
  return (
    <div className="mb-[0.5em] text-text-tertiary">
      <Text className="my-xs">ID: {anomaly.id}</Text>
      <div>
        <Text className="my-xs">Start Time:</Text>
        <Text className="my-xs">{humanDateTime(anomaly.start)}</Text>
      </div>
      {anomaly.end && (
        <Text className="my-xs">
          Duration:{' '}
          <span title={exactDuration(anomaly.start, anomaly.end)}>
            {humanDuration(anomaly.start, anomaly.end)}
          </span>
        </Text>
      )}
      <Text className="my-xs">
        Peak Score:{' '}
        <span>{anomaly.score ? anomaly.score.toFixed(4) : '-'}</span>
      </Text>
    </div>
  )
}

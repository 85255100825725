import classNames from 'classnames'
import { Text } from 'src/components/ui'

interface AnomalyLiveTagProps {
  inProgress: boolean
  className?: string
}

export function AnomalyLiveTag({
  inProgress,
  className,
}: AnomalyLiveTagProps): JSX.Element {
  if (inProgress) {
    return (
      <div
        className={classNames(
          'flex items-center justify-center rounded-2xs bg-background-danger-tertiary py-2xs',
          className,
        )}
      >
        <Text bold className="text-white" variant="description">
          LIVE
        </Text>
      </div>
    )
  }

  return <></>
}

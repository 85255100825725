import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { Icon, Text } from 'src/components/ui'

type Props = {
  initialIsOpen?: boolean
  header: {
    icon: IconProp
    iconClassName?: string
    text: string
  }
  content: React.ReactNode
}

export function OpcUaHierarchySyncLayout({
  initialIsOpen = false,
  header,
  content,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(initialIsOpen)
  return (
    <motion.header
      initial={false}
      onClick={() => setIsOpen(!isOpen)}
      className="flex cursor-pointer flex-col gap-s rounded-2xs border border-solid border-border bg-background-hover p-s"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2xs">
          <Icon icon={header.icon} className={header.iconClassName} />
          <Text bold>{header.text}</Text>
        </div>
        <Icon
          icon={light('chevron-down')}
          className={classNames(
            'transition duration-300',
            isOpen && 'rotate-180',
          )}
        />
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2 }}
            className="flex flex-col gap-s overflow-hidden"
          >
            {content}
          </motion.section>
        )}
      </AnimatePresence>
    </motion.header>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Tooltip, Text, Icon } from 'src/components/ui'
import { OnlyModelProps } from 'src/types'
import { prettyErrorScore } from 'src/utility/numbers'
import { PropertyContainer } from './PropertyContainer'

type ScoreDisplayProps = {
  modelType: string
  score: number
  label: string
  tooltipContent: React.ReactNode
}

function ScoreDisplay({
  modelType,
  score,
  label,
  tooltipContent,
}: ScoreDisplayProps): JSX.Element {
  return (
    <PropertyContainer modelType={modelType}>
      <Text bold>{label}:</Text>
      <Text>{prettyErrorScore(score)} </Text>
      <Tooltip render={tooltipContent} direction="right">
        <Icon icon={light('info-circle')} size="regular" />
      </Tooltip>
    </PropertyContainer>
  )
}

export function ModelScores({ model }: OnlyModelProps): JSX.Element {
  const { valMae, valRSquare } = model.activeTrainedModel || {}

  if (!valMae && !valRSquare) return <></>

  return (
    <div className="flex flex-col gap-s whitespace-nowrap small:flex-row small:items-center small:gap-l">
      {valMae && (
        <ScoreDisplay
          modelType={model.type.name}
          score={valMae}
          label="Error Score"
          tooltipContent={() => (
            <>
              <ul className="mt-0 px-s">
                <li>
                  <Text>
                    The error score, ranging 0 to 1, measures model accuracy
                    where lower scores indicates better predictions and a score
                    of 0 means perfect accuracy
                  </Text>
                </li>
                <li>
                  <Text>
                    It is based on the mean absolute error, showing the average
                    difference between predictions and actual values
                  </Text>
                </li>
                <li>
                  <Text>
                    The error score can be used to compare multiple models as
                    long as the output tag and time window are identical
                  </Text>
                </li>
              </ul>
              <Text>
                Go to{' '}
                <a
                  className="font-500 text-text-success-secondary no-underline"
                  href="https://docs.intelecy.io/product/analysis/models/improving-models"
                  target="_blank"
                  rel="noreferrer"
                >
                  Improving models
                </a>{' '}
                to learn about how you can reduce the error score.
              </Text>
            </>
          )}
        />
      )}
      {valRSquare && (
        <ScoreDisplay
          modelType={model.type.name}
          score={valRSquare}
          label="R-squared"
          tooltipContent={() => (
            <>
              <Text>
                R squared (R²), usually ranging 0 to 1, measures how much of the
                data's variability is explained by the model with the predicted
                values:
              </Text>
              <ul className="mt-0 px-s">
                <li>
                  <Text>A score of 1 means a perfect prediction</Text>
                </li>
                <li>
                  <Text>
                    A score of 0 means the model can only predict the average of
                    the data
                  </Text>
                </li>
                <li>
                  <Text>
                    A negative score means the model cannot predict the average
                    value which might happen because of extreme outliers in the
                    data or because the inputs chosen do not help predict the
                    output
                  </Text>
                </li>
              </ul>
              <Text>
                To improve your R² score, focus on selecting better input tags.
                Go to{' '}
                <a
                  className="font-500 text-text-success-secondary no-underline"
                  href="https://docs.intelecy.io/product/analysis/models/improving-models"
                  target="_blank"
                  rel="noreferrer"
                >
                  Improving models
                </a>{' '}
                to learn more.
              </Text>
            </>
          )}
        />
      )}
    </div>
  )
}

import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { gte } from 'semver'
import { Icon, Text, Tooltip } from 'src/components/ui'

type Props = {
  version: string | null
  latestVersion?: string
  onUpgradeClick: () => void
  bold?: boolean
}

export function GatewayVersionRenderer({
  bold,
  version,
  latestVersion,
  onUpgradeClick,
}: Props): JSX.Element {
  if (version === null) return <Text>-</Text>
  if (!latestVersion) return <Text bold={bold}>{version}</Text>

  const isLatestMajorVersion = gte(version, latestVersion)

  return (
    <div
      className="flex cursor-pointer items-center gap-xs"
      onClick={onUpgradeClick}
    >
      <Text bold={bold}>{version}</Text>
      <Tooltip
        className="!max-w-[195px]"
        direction="bottom-start"
        render={() => {
          if (isLatestMajorVersion) {
            return <Text variant="description">Gateway is up to date</Text>
          } else {
            return (
              <div className="flex flex-col">
                <Text>This gateway is outdated.</Text>
                <Text>
                  Click{' '}
                  <span className="cursor-pointer text-text-success-secondary">
                    here
                  </span>{' '}
                  to select new version.
                </Text>
              </div>
            )
          }
        }}
      >
        <Icon
          icon={
            isLatestMajorVersion
              ? light('circle-check')
              : light('circle-exclamation')
          }
          className={classNames(
            isLatestMajorVersion ? 'text-icon-success' : 'text-icon-warning',
          )}
        />
      </Tooltip>
    </div>
  )
}

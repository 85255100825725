import React from 'react'
import { Text, Button } from 'src/components/ui'
import { useTag } from 'tags/api'
import { Record } from '../steps'
import { DisplayInputTags, AddInputTagsModal } from './components'

interface InputStepProps {
  outputTag?: string | null
  inputTags?: string[]
  model: Record
  addInputTag: (tag: string) => void
  removeInputTag: (tag: string) => void
}

export function InputStep({
  outputTag,
  inputTags,
  addInputTag,
  removeInputTag,
  model,
}: InputStepProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false)
  const { data: tag } = useTag(outputTag ?? '')

  return (
    <>
      <Text variant="title" bold className="my-m text-center !text-3xl">
        Which values are good predictors of{' '}
        <span>{tag?.displayName || 'the output'}</span>?
      </Text>
      <Text className="my-s">
        A good model requires a set of input tags that can explain how the
        output tag changes over time. Select the input tags that help explain
        how the output tag changes over time.
      </Text>
      {model.modelType === 'ForecastModel' && (
        <Text className="mb-m">
          <span className="font-500">{model.outputTag} </span> has been added.
          Forecast models usually perform better with output as input.
        </Text>
      )}
      <DisplayInputTags
        inputTags={inputTags}
        removeInputTag={removeInputTag}
        model={model}
      />
      <Button
        title="+ Add Input Tag"
        variant="primary"
        className="mb-[0.5em]"
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <AddInputTagsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          inputTags={inputTags}
          addInputTag={addInputTag}
          model={model}
        />
      )}
    </>
  )
}

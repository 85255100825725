import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { DynamicTable } from 'src/components/ui'
import { useAssetHierarchy } from 'src/contexts/assetHierarchy/useAssetHierarchy'
import { SiteRole, Tag } from 'src/types'
import { useSite } from 'src/contexts/site'
import { ChartOptions, ChartType } from 'src/types/chartTypes'
import { TagDto } from 'src/services'
// TODO: This will get moved
import { getAssetTagsTableConfig } from 'src/components/ui/tables/DynamicTable'
import EditTagForm from './EditTagForm'

type Props = {
  assetTags: TagDto[]
  shouldDeselect?: boolean
}

export function AssetTagTable({
  assetTags,
  shouldDeselect,
}: Props): JSX.Element {
  const [isTagEditModalOpen, setIsTagEditModalOpen] = useState(false)
  const { selectedTags, setSelectedTags } = useAssetHierarchy()
  const { rootLink, viewerRole: role, id: factory } = useSite()
  const navigate = useNavigate()
  const selectedTagsRef = useRef(selectedTags)

  useEffect(() => {
    selectedTagsRef.current = selectedTags
  }, [selectedTags])

  const generateTrendLink = useCallback((): string => {
    const charts = selectedTagsRef.current
      .map(el => el.tagName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map<ChartOptions>((tagName, id) => ({
        type: ChartType.TimeSeries,
        id,
        data: [{ type: 'tag', id: tagName }],
      }))
    return `${rootLink}/trend?${queryString.stringify({
      charts: JSON.stringify(charts),
    })}`
  }, [rootLink])

  const handleViewInTrend = useCallback(() => {
    navigate(generateTrendLink())
  }, [generateTrendLink, navigate])

  const handleEditTag = useCallback(
    (tag: Tag) => {
      setSelectedTags([tag])
      setIsTagEditModalOpen(true)
    },
    [setSelectedTags],
  )

  const contextItems = useMemo(
    () => [
      {
        id: '1',
        name: 'View in Trend',
        action: handleViewInTrend,
      },
    ],
    [handleViewInTrend],
  )

  const getContextMenuItems = useCallback(() => {
    return contextItems
  }, [contextItems])

  const AssetTagsTable = useMemo(() => {
    return (
      <DynamicTable
        id={`${factory}-assetTagsTable`}
        maxHeightFull
        config={getAssetTagsTableConfig({
          canDrag: role === SiteRole.ADMIN,
          rootLink,
          canEdit: role === SiteRole.ADMIN,
          onEditTag: handleEditTag,
        })}
        data={assetTags.map(t => ({
          ...t,
          id: t.id.toString(),
          label: t.overriddenLabel || t.automaticLabel,
          displayName: t.displayName || t.tagName,
        }))}
        selectMultipleRows
        actions={{
          setSelectedRows: setSelectedTags,
        }}
        shouldDeselect={shouldDeselect}
        getContextItems={getContextMenuItems}
        fuzzySearch
        fuzzySearchField={['tagName', 'description', 'displayName', 'engUnit']}
      />
    )
  }, [
    factory,
    role,
    rootLink,
    handleEditTag,
    assetTags,
    setSelectedTags,
    shouldDeselect,
    getContextMenuItems,
  ])

  return (
    <>
      {AssetTagsTable}
      <EditTagForm
        tagData={{
          ...selectedTags[0],
          label:
            selectedTags[0]?.overriddenLabel || selectedTags[0]?.automaticLabel,
          displayName: selectedTags[0]?.displayName || selectedTags[0]?.tagName,
          unit: selectedTags[0]?.engUnit,
        }}
        isOpen={isTagEditModalOpen}
        setIsOpen={setIsTagEditModalOpen}
      />
    </>
  )
}

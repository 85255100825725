import { Link } from 'react-router-dom'
import { Card } from 'src/components/ui'
import { useTitle } from 'src/utility'
import {
  AnomalyCommentsCard,
  AnomalyDistributionCard,
  AssetStatusCard,
  MetropolisDashboardCard,
} from './cards'

type DashboardProps = {
  kind: 'default' | 'metropolis'
  rootLink?: string
}

export function DashboardPage({
  kind,
  rootLink = '',
}: DashboardProps): JSX.Element {
  useTitle('Dashboard')

  if (kind === 'metropolis') {
    return (
      <div className="mx-4 mb-4">
        <Card>
          <MetropolisDashboardCard />
        </Card>
      </div>
    )
  }

  return (
    <div className="mx-4 mb-4 grid grid-cols-6 gap-4">
      <Card padding="24px" className="col-span-6 @container">
        <AnomalyDistributionCard />
      </Card>
      <Card padding="24px" className="col-span-6 md:col-span-4">
        <AnomalyCommentsCard />
      </Card>

      <Card
        as={Link}
        to={`${rootLink}/assets`}
        className="col-span-6 self-start md:col-span-2"
        $hovercolor
      >
        <AssetStatusCard />
      </Card>
    </div>
  )
}

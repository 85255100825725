import classNames from 'classnames'
import { useState } from 'react'
import { TextInput, Text } from 'src/components/ui'
import { useCharts } from 'src/contexts/charts'
import { ChartOptions } from 'src/types/chartTypes'

type Props = {
  chart: ChartOptions
}

export function ChartName({ chart }: Props): JSX.Element {
  const [isEditingName, setIsEditingName] = useState(false)
  const [name, setName] = useState(chart.title ?? '')
  const { setChart } = useCharts()

  // If user clicks outside the input, save the name
  function handleFocusChange(f: boolean): void {
    if (!f) {
      if (name !== chart.title) {
        setChart(chart.id, { ...chart, title: name })
      }
      setIsEditingName(false)
    }
  }

  return isEditingName ? (
    <form
      className="w-full"
      onSubmit={e => {
        e.preventDefault()
        handleFocusChange(false)
      }}
    >
      <TextInput
        autofocus
        containerStyles="py-2xs"
        className="w-full"
        value={name}
        onChange={e => setName(e.target.value)}
        setFocus={handleFocusChange}
        onClickOutside={() => handleFocusChange(false)}
      />
    </form>
  ) : (
    <div
      onClick={() => setIsEditingName(true)}
      className="w-full max-w-[calc(100%-30px)] rounded-2xs px-xs py-2xs hover:outline hover:outline-1 hover:outline-border"
    >
      <Text
        className={classNames(
          'pl-2xs max-w-full truncate hover:cursor-text',
          !chart.title && 'text-text-disabled',
        )}
        bold
      >
        {name || 'Untitled'}
      </Text>
    </div>
  )
}

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Text } from 'src/components/ui'
import { humanDate, humanDateTime, humanTime } from 'src/utility/time'
import { OpcUaHierarchySyncLayout } from './OpcUaHierarchySyncLayout'

type Props = {
  error: string
  failedSyncTime?: Date
  latestSuccessfulSyncTime?: Date
}

export function ErrorSyncContent({
  error,
  failedSyncTime,
  latestSuccessfulSyncTime,
}: Props): JSX.Element {
  return (
    <OpcUaHierarchySyncLayout
      initialIsOpen
      header={{
        text: failedSyncTime
          ? `Last Synchronization Failed at ${humanDateTime(
              latestSuccessfulSyncTime,
            )}`
          : 'Last Synchronization Failed',
        icon: regular('circle-exclamation'),
        iconClassName: 'text-icon-danger',
      }}
      content={
        <div className="flex flex-col gap-s pl-[20px]">
          <Text>{error}</Text>
          {latestSuccessfulSyncTime ? (
            <Text>
              Latest successful synchronization{' '}
              <span className="font-500">
                {humanDate(latestSuccessfulSyncTime)},
              </span>{' '}
              {humanTime(latestSuccessfulSyncTime)}{' '}
            </Text>
          ) : (
            <Text>No previous successful synchronization</Text>
          )}
        </div>
      }
    />
  )
}

export enum ProgressBarVariant {
  NORMAL = 'normal',
  THIN = 'thin',
}

export type ProgressBarProps = {
  value: number
  variant?: ProgressBarVariant
  className?: string
  indicatorClassName?: string
}
